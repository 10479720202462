import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import orders from "./orders/saga";
import clients from "./clients/saga";
import client from "./client/saga";
import shoppers from "./shoppers/saga";
import couriers from "./couriers/saga";
import courier from "./courier/saga";
import order from "./order/saga";
import restaurant from "./restaurant/saga";
import statistics from "./statistics/saga";
import city from "./city/saga";
import citiesMap from "./citiesMap/saga";
import promocode from "./promocode/saga";
import partners from "./partners/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    orders(),
    clients(),
    client(),
    shoppers(),
    couriers(),
    courier(),
    order(),
    restaurant(),
    statistics(),
    city(),
    citiesMap(),
    promocode(),
    partners(),
  ]);
}
