import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import shopperActions from "../shoppers/actions";
import { apiUrls } from "../../settings";
import pathToReg from "path-to-regexp";
import { checkAccessToken } from "../auth/saga";
import isEmpty from "lodash/isEmpty";
import { globalEventBus, ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from "../../helpers/globalEventBus";
import { parseReceiptStringOrNull } from "../../helpers/order";

function transformData(data) {
  let accessCodeEntrance = !!data.access_code_entrance ? data.access_code_entrance : "";
  const resultServices = data.services.map((item) => ({ ...item, transfered_count: item.count }));
  if (data.bundle_type === "foodmarket" && data.children) {
    data.children.forEach((child) => {
      child.services.forEach(({ service }) => {
        const resultService = resultServices.find((resultService) => resultService.service.id === service.id);
        if (!resultService) return;
        resultService.placeName = child.place.name;
      });
    });
  }

  return {
    ...data,
    to_address: !!data.to_address ? data.to_address : data.address,
    access_code_entrance: accessCodeEntrance,
    services: resultServices,
  };
}

export function* loadData() {
  yield takeEvery(actions.START_LOADING_ORDER, function* (action) {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      let url = pathToReg.compile(apiUrls.orderOne);
      let order = yield call(
        LoaderData.get,
        url({
          id: action.data,
        }),
      );
      if (order.success) {
        let transfData = transformData(order.data);
        yield put({
          type: actions.SUCCESS_LOADING_ORDER,
          data: {
            order: transfData,
          },
        });

        if (transfData.shopper_type === "service") {
          yield put({
            type: shopperActions.INIT_SHOPPERS,
            data: {
              count: 1000,
              blocked: false,
              shiftNow: true,
              cityDepended: true,
              place_id: transfData.place.id,
            },
          });
        }

        yield put({
          type: actions.LOAD_TRACKS_ORDER,
          data: action.data,
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_ORDER,
          payload: order,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_ORDER,
        payload: e,
      });
    }
  });
}

export function* init() {
  yield takeEvery(actions.INIT_ORDER, function* ({ payload }) {
    yield put({
      type: actions.START_LOADING_ORDER,
      data: payload,
    });
  });
}

export function* loadTracks() {
  yield takeEvery("LOAD_TRACKS_ORDER", function* (action) {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      let url = pathToReg.compile(apiUrls.orderTracks);
      let tracks = yield LoaderData.get(
        url({
          id: action.data,
        }),
      );
      if (tracks.success) {
        yield put({
          type: actions.CHANGE_TRACKS_ORDER,
          data: {
            tracks: tracks.data,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_ORDER,
          payload: tracks,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_ORDER,
        payload: e,
      });
    }
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_ORDER, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, {
      id: "common.error",
      desc: payload.message || payload.error.message || payload.error.dev_err[0],
    });
  });
}

export function* updateOrderReceipts() {
  yield takeEvery(actions.UPDATE_RECEIPTS, function* (action) {
    try {
      yield call(checkAccessToken);

      const state = yield select();

      if (!state.Auth.idToken) {
        yield cancel();
      }

      const newReceipts = [action.payload];
      const parsedReceipts = newReceipts.map(parseReceiptStringOrNull);
      if (parsedReceipts.some((parsedReceipt) => !parsedReceipt))
        return yield globalEventBus.emit(ERROR_NOTIFICATION, {
          id: "common.error",
          desc: "order.paper_bill.edit_error.validation_failed.description",
        });

      const orderId = state.Order.data.id;

      yield put(actions.startLoading());

      const url = pathToReg.compile(apiUrls.orderFinishShopper);
      const response = yield call(
        LoaderData.post,
        url({
          id: orderId,
        }),
        {
          receipts: parsedReceipts,
        },
      );

      if (response.success) {
        yield* _updateOrderStatus({ payload: "on_way" });

        if (typeof action.callback === "function") action.callback(response);

        yield put({
          type: actions.LOAD_TRACKS_ORDER,
          data: orderId,
        });
      } else {
        if (response.status === 400) {
          const state = yield select();

          yield globalEventBus.emit(ERROR_NOTIFICATION, {
            id: "common.server_error",
            desc: "order.paper_bill.save_error.bad_request.description",
          });

          yield put({
            type: actions.INIT_ORDER,
            payload: state.Order.primaryData.id,
          });

          return;
        }

        yield put({
          type: actions.FAIL_LOADING_ORDER,
          payload: response,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_UPDATE_RECEIPTS,
        payload: e,
      });
    }
  });
}

export function* failUpdateOrderReceipts() {
  yield takeEvery(actions.FAIL_UPDATE_RECEIPTS, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, {
      id: "common.error",
      desc: payload.message || payload.error.message || payload.error.dev_err[0],
    });
  });
}

function* _updateOrderStatus(action) {
  try {
    yield call(checkAccessToken);

    const state = yield select();

    if (!state.Auth.idToken) {
      yield cancel();
    }

    const orderID = state.Order.data.id;

    yield put(actions.startLoading());

    const order = yield call(LoaderData.post, apiUrls.orderUpdate, {
      order_id: orderID,
      order_state: action.payload,
    });

    if (order.success) {
      yield put(
        actions.successUpdateStatus({
          newState: order.data.state,
          states: order.data.states,
        }),
      );

      yield globalEventBus.emit(SUCCESS_NOTIFICATION, { id: "order.card.status_is_updated" });

      yield put({
        type: actions.LOAD_TRACKS_ORDER,
        data: orderID,
      });
    } else {
      if (order.status === 409) {
        const state = yield select();

        yield globalEventBus.emit(ERROR_NOTIFICATION, {
          id: "common.server_error",
          desc: "order.card.edit_error.description",
        });

        yield put({
          type: actions.INIT_ORDER,
          payload: state.Order.primaryData.id,
        });

        return;
      }
      yield put({
        type: actions.FAIL_LOADING_ORDER,
        payload: order,
      });
    }
  } catch (e) {
    yield put({
      type: actions.FAIL_UPDATE_STATUS,
    });
  }
}
export function* updateOrderStatus() {
  yield takeEvery(actions.UPDATE_STATUS, _updateOrderStatus);
}

export function* updateOrderRequiredCourierType() {
  yield takeEvery(actions.UPDATE_REQUIRED_COURIER_TYPE, (action) =>
    _save({ payload: { required_courier_type: action.payload } }),
  );
}

function* _save(action) {
  try {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    yield put(actions.startLoading());

    const additionalRequestBodyData = {};

    const newCouriersArrayIsNotEmpty = action.payload.couriers ? action.payload.couriers.length !== 0 : false;
    const orderCouriersArrayIsNotEmpty = state.Order.data.couriers.length !== 0;

    if (newCouriersArrayIsNotEmpty && orderCouriersArrayIsNotEmpty) {
      additionalRequestBodyData["courier_status"] = "courier-assigned";
    }

    const orderId = state.Order.data.id;
    const order = yield LoaderData.post(apiUrls.orderUpdate, {
      order_id: orderId,
      ...action.payload,
      ...additionalRequestBodyData,
    });
    if (order.success) {
      yield globalEventBus.emit(SUCCESS_NOTIFICATION, { id: "order.card.data_is_updated" });
      let transfData = transformData(order.data);
      yield put({
        type: actions.SUCCESS_LOADING_ORDER,
        data: {
          order: transfData,
        },
      });

      yield put({
        type: actions.LOAD_TRACKS_ORDER,
        data: orderId,
      });
    } else {
      if (order.status === 409) {
        const state = yield select();

        yield globalEventBus.emit(ERROR_NOTIFICATION, {
          id: "common.server_error",
          desc: "order.card.edit_error.description",
        });

        yield put({
          type: actions.INIT_ORDER,
          payload: state.Order.primaryData.id,
        });

        return;
      }

      yield put({
        type: actions.FAIL_LOADING_ORDER,
        payload: order,
      });
    }
  } catch (e) {
    yield put({
      type: actions.FAIL_LOADING_ORDER,
      payload: e,
    });
  }
}

export function* save() {
  yield takeEvery(actions.SAVE_ORDER, _save);
}

export function* saveCountComposition() {
  yield takeEvery(actions.SAVE_COUNT_COMPOSITION_ORDER, function* (action) {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      yield put(actions.startLoading());

      let url = pathToReg.compile(apiUrls.orderCompositionUpdate);
      let idOrder = state.Order.data.id;

      const { services, ...rest } = action.payload;

      let order = yield LoaderData.post(
        url({
          id: idOrder,
        }),
        {
          order_id: idOrder,
          ...services,
        },
      );

      if (order.success) {
        yield globalEventBus.emit(SUCCESS_NOTIFICATION, { id: "order.card.position_is_updated" });
        if (!isEmpty(rest)) {
          yield put({ type: actions.SAVE_ORDER, payload: rest });
          return;
        }
        let transfData = transformData(order.data);
        yield put({
          type: actions.SUCCESS_LOADING_ORDER,
          data: {
            order: transfData,
          },
        });

        yield put({
          type: actions.LOAD_TRACKS_ORDER,
          data: idOrder,
        });
      } else {
        if (order.status === 409) {
          const state = yield select();
          yield globalEventBus.emit(ERROR_NOTIFICATION, {
            id: "common.server_error",
            desc: "order.card.edit_error.description",
          });
          yield put({
            type: actions.INIT_ORDER,
            payload: state.Order.primaryData.id,
          });
          return;
        }

        yield put({
          type: actions.FAIL_LOADING_ORDER,
          payload: order,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_ORDER,
        payload: e,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadData),
    fork(init),
    fork(failLoading),
    fork(save),
    fork(updateOrderStatus),
    fork(updateOrderRequiredCourierType),
    fork(updateOrderReceipts),
    fork(failUpdateOrderReceipts),
    fork(saveCountComposition),
    fork(loadTracks),
  ]);
}
