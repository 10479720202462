import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect, useStore } from "react-redux";
import { Switch } from "react-router";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import PageView from "./HOCS/PageView";
import { history } from "./redux/store";
import { useLanguage } from "./helpers/language/useLanguage";

function RestrictedRoute({ component: Component, isLoggedIn, ...rest }) {
  const language = useLanguage();
  const render = React.useCallback(
    (props) => {
      if (isLoggedIn) return <Component {...props} />;
      return <Redirect to={{ pathname: "/signin", state: { from: props.location } }} />;
    },
    [language],
  );

  return <Route {...rest} render={render} />;
}

const MemoRestrictedRoute = React.memo(RestrictedRoute);

const PublicRoutes = ({ isLoggedIn }) => {
  const store = useStore();
  return (
    <ConnectedRouter store={store} history={history}>
      <PageView>
        <Switch>
          <Route path="/signin" component={asyncComponent(() => import("./containers/Page/signin"))} />
          <Route path="/omnidesk-sso" component={asyncComponent(() => import("./containers/Page/signin"))} />
          <MemoRestrictedRoute path="/dashboard" component={App} isLoggedIn={isLoggedIn} />
          <Redirect to="/dashboard" />
        </Switch>
      </PageView>
    </ConnectedRouter>
  );
};

export default connect((state) => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
