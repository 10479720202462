const actions = {
  START_LOADING_SHOPPERS: "START_LOADING_SHOPPERS",
  SUCCESS_LOADING_SHOPPERS: "SUCCESS_LOADING_SHOPPERS",
  FAIL_LOADING_SHOPPERS: "FAIL_LOADING_SHOPPERS",
  CHANGE_PAGE_SHOPPERS: "CHANGE_PAGE_SHOPPERS",
  RESET_STATE_SHOPPERS: "RESET_STATE_SHOPPERS",
  INIT_SHOPPERS: "INIT_SHOPPERS",
  INIT_SHOPPERS_BY_PARAMS: "INIT_SHOPPERS_BY_PARAMS",
  init: (data) => ({
    type: actions.INIT_SHOPPERS,
    payload: data,
  }),
  resetState: () => ({
    type: actions.RESET_STATE_SHOPPERS,
  }),
};
export default actions;
