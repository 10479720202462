import querystring from "query-string";

import { apiUrls } from "../../settings";
import { getWhiteListHeader } from "../remoteConfig";
import { getToken } from "../utility";

import { APP_TOKEN_NAME, APP_TOKEN_VALUE, WHITE_LIST_HEADER_NAME } from "./constants";

export default class LoaderData {
  static acceptLanguage = null;

  static setAcceptLanguage(acceptLanguage) {
    LoaderData.acceptLanguage = acceptLanguage;
  }

  static createHeaders(withAuth = true) {
    const headers = new Headers();
    const whiteListHeaderValue = getWhiteListHeader();
    headers.append(APP_TOKEN_NAME, APP_TOKEN_VALUE);
    headers.append("Content-Type", "application/json");
    headers.append("Accept-Language", LoaderData.acceptLanguage);
    if (whiteListHeaderValue) headers.append(WHITE_LIST_HEADER_NAME, whiteListHeaderValue);
    if (withAuth) {
      const token = getToken().token;
      headers.append("x-auth-token", token);
    }

    return headers;
  }

  static get(url, params) {
    const optionsSend = {
      mode: "cors",
      credentials: "include",
      headers: LoaderData.createHeaders(),
    };

    return fetch(`${apiUrls.rootApiUrl}${url}?${querystring.stringify(params)}`, optionsSend).then((response) =>
      response.json(),
    );
  }

  static refreshToken(url, token) {
    const headers = LoaderData.createHeaders(false);
    headers.append("x-refresh-token", token);
    const optionsSend = {
      mode: "cors",
      credentials: "include",
      headers,
    };

    return fetch(`${apiUrls.rootApiUrl}${url}`, optionsSend).then((response) => response.json());
  }

  static post(url, params, withAuth = true) {
    const optionsSend = {
      mode: "cors",
      method: "post",
      withCredentials: true,
      credentials: "include",
      headers: LoaderData.createHeaders(withAuth),
      body: JSON.stringify(params),
    };
    return fetch(`${apiUrls.rootApiUrl}${url}`, optionsSend).then(
      async (response) => {
        try {
          const data = await response.json();
          return { ...data, status: response.status };
        } catch (error) {
          return { status: response.status };
        }
      },
      async (response) => {
        try {
          const data = await response.json();
          return { ...data, status: response.status };
        } catch (error) {
          return { status: response.status };
        }
      },
    );
  }
}
