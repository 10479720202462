import React from "react";
import Centrifuge from "centrifuge";
import { getToken } from "../../helpers/utility";
import { getWhiteListHeader } from "../../helpers/remoteConfig";
import { connect } from "react-redux";
import { apiUrls } from "../../settings";
import messagesTypes from "./messagesTypes";
import { notification, Icon } from "antd";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import NotificationBody from "../../usedComponents/NotificationPhoneBody";

import { APP_TOKEN_NAME, APP_TOKEN_VALUE, WHITE_LIST_HEADER_NAME } from "../../helpers/api/constants";

class CentrifugeContainer extends React.Component {
  constructor(props) {
    super(props);
    let token = this.props.auth.token;
    let data = this.props.auth.tokenData;
    const whiteListHeaderValue = getWhiteListHeader();
    const params = {
      subscribeEndpoint: `${apiUrls.rootApiUrl}${apiUrls.centrifugeSubscribe}`,
      refreshEndpoint: `${apiUrls.rootApiUrl}${apiUrls.centrifugeRefresh}`,
      refreshData: this.getToken(),
      subscribeHeaders: {
        [APP_TOKEN_NAME]: APP_TOKEN_VALUE,
        "x-auth-token": token,
      },
      refreshHeaders: {
        [APP_TOKEN_NAME]: APP_TOKEN_VALUE,
        "x-auth-token": token,
      },
    };

    if (whiteListHeaderValue) {
      params.subscribeHeaders[WHITE_LIST_HEADER_NAME] = whiteListHeaderValue;
      params.refreshHeaders[WHITE_LIST_HEADER_NAME] = whiteListHeaderValue;
    }

    this.__instance = new Centrifuge(`wss://centrifugo.${process.env.__CONFIG__.site}/connection/websocket`, params);
    this.__instance.setToken(token);
    try {
      this.__instance.subscribe("$backoffice", function (message) {
        console.log(message);
      });
      this.__instance.subscribe(`$backoffice#${data.sub}`, (message) => this.handlePrivateManagerMessage(message));
      this.__instance.connect();
    } catch {
      notification.error({
        message: this.props.intl.formatMessage({ id: "common.error" }),
        duration: 0,
      });
    }
  }

  handlePrivateManagerMessage(message) {
    let data = message.data.data;

    switch (data.type) {
      case messagesTypes.TYPE_BEGIN_PHONE:
        this.handlePhoneStart(message);
        return;

      case messagesTypes.TYPE_END_PHONE:
        this.handlePhoneEnd(message);
        return;

      default:
        return;
    }
  }

  generateDataByPhone(message) {
    let buttonText = "";
    let link = "";
    let data = message.data.data;
    if (!!data.client && !!data.client.active_order) {
      buttonText = this.props.intl.formatMessage({
        id: "orders.order_card",
      });
      link = `/dashboard/order/${data.client.active_order.id}`;
    }
    if (!!data.client && !buttonText) {
      buttonText = this.props.intl.formatMessage({
        id: "clients.client_card",
      });
      link = `/dashboard/clients/${data.client.id}`;
    }

    if (!!data.partner && !buttonText) {
      buttonText = this.props.intl.formatMessage({
        id: "restaurant.partner_card",
      });
      link = `/dashboard/partners/${data.partner.id}`;
    }

    return {
      buttonText,
      link,
    };
  }

  handlePhoneStart(message) {
    let data = message.data.data;
    let generatedDataByPhone = this.generateDataByPhone(message);
    notification.success({
      duration: null,
      key: data.call_id.toString(),
      message: this.props.intl.formatMessage({
        id: "common.coming_call",
      }),
      description: (
        <NotificationBody
          buttonText={generatedDataByPhone.buttonText}
          onClickButtonHandler={() => this.handlePhoneNotificationButton(generatedDataByPhone.link)}
          message={message.data.message}
        />
      ),
      icon: <Icon type="phone" style={{ color: "#108ee9" }} />,
    });
  }

  handlePhoneNotificationButton(link) {
    this.props.history.push(link);
  }

  handlePhoneEnd(message) {
    let data = message.data.data;
    notification.close(data.call_id.toString());
  }

  getToken() {
    return {
      token: this.props.auth.token,
    };
  }

  render() {
    return null;
  }
}

export default withRouter(
  injectIntl(
    connect((state) => ({
      auth: state.Auth,
    }))(CentrifugeContainer),
  ),
);
