import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from "../../helpers/globalEventBus";

export function* sendData() {
  yield takeEvery(actions.START_SEND_PROMO, function* (action) {
    try {
      yield call(checkAccessToken);
      let state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      let stats = yield LoaderData.post(apiUrls.promocodeCreate, action.payload);
      if (stats.success) {
        yield globalEventBus.emit(SUCCESS_NOTIFICATION, {
          id: "common.success",
          desc: "client.card.success_send_promocode.description",
        });
      } else {
        if (stats.status === 409) {
          yield globalEventBus.emit(ERROR_NOTIFICATION, {
            id: "common.server_error",
            desc: "client.card.error_send_promocode.description",
          });
          return;
        }

        yield put({
          type: actions.FAIL_SEND_PROMO,
          payload: stats,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_SEND_PROMO,
        payload: e,
      });
    }
  });
}

export function* failSending() {
  yield takeEvery(actions.FAIL_SEND_PROMO, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, {
      id: "common.error",
      desc: payload.message || payload.error.message,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(sendData), fork(failSending)]);
}
