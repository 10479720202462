import { isNull, isUndefined } from "lodash";
import queryString from "query-string";
import moment from "moment";

import { getLocalDate } from "../utility";

const DEFAULT_RECEIPT_STRING_KEY_FORMATTER = (x) => x;
const RECEIPT_STRING_NECESSARY_KEYS = {
  t: "t",
  s: "s",
  fn: "fn",
  i: "i",
  fp: "fp",
};
const RECEIPT_STRING_NECESSARY_KEYS_AND_FORMATTERS = {
  [RECEIPT_STRING_NECESSARY_KEYS.t]: (dateString) => {
    const momentLocal = getLocalDate(moment(dateString));
    if (momentLocal) return momentLocal.toDate().toISOString();

    return null;
  },
  [RECEIPT_STRING_NECESSARY_KEYS.s]: DEFAULT_RECEIPT_STRING_KEY_FORMATTER,
  [RECEIPT_STRING_NECESSARY_KEYS.fn]: DEFAULT_RECEIPT_STRING_KEY_FORMATTER,
  [RECEIPT_STRING_NECESSARY_KEYS.i]: DEFAULT_RECEIPT_STRING_KEY_FORMATTER,
  [RECEIPT_STRING_NECESSARY_KEYS.fp]: DEFAULT_RECEIPT_STRING_KEY_FORMATTER,
};
const RECEIPT_RESPONSE_NECESSARY_KEYS = {
  price: "price",
  purchased_at: "purchased_at",
  fn: "fn",
  fp: "fp",
  i: "i",
};
const RECEIPT_STRING_TO_RESPONSE_KEY_MAPPING = {
  [RECEIPT_STRING_NECESSARY_KEYS.t]: RECEIPT_RESPONSE_NECESSARY_KEYS.purchased_at,
  [RECEIPT_STRING_NECESSARY_KEYS.s]: RECEIPT_RESPONSE_NECESSARY_KEYS.price,
  [RECEIPT_STRING_NECESSARY_KEYS.fn]: RECEIPT_RESPONSE_NECESSARY_KEYS.fn,
  [RECEIPT_STRING_NECESSARY_KEYS.fp]: RECEIPT_RESPONSE_NECESSARY_KEYS.fp,
  [RECEIPT_STRING_NECESSARY_KEYS.i]: RECEIPT_RESPONSE_NECESSARY_KEYS.i,
};
const RECEIPT_TYPE = "qr_code";

export const parseReceiptStringOrNull = (receiptString) => {
  if (!receiptString || typeof receiptString !== "string") return null;

  const parsed = queryString.parse(receiptString);
  const parsedKeys = Object.keys(parsed);
  const necessaryKeys = Object.keys(RECEIPT_STRING_NECESSARY_KEYS_AND_FORMATTERS);
  if (necessaryKeys.some((key) => parsedKeys.findIndex((pK) => pK === key) === -1)) return null;

  let result = { type: RECEIPT_TYPE };
  for (const necessaryKey of necessaryKeys) {
    const necessaryValue = RECEIPT_STRING_NECESSARY_KEYS_AND_FORMATTERS[necessaryKey](parsed[necessaryKey]);
    if (isNull(necessaryValue) || isUndefined(necessaryValue)) {
      result = null;
      break;
    }

    const mappedKey = RECEIPT_STRING_TO_RESPONSE_KEY_MAPPING[necessaryKey];
    if (!mappedKey) continue;

    result[mappedKey] = necessaryValue;
  }

  return result;
};
