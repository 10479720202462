import actions from "./actions";

const initState = {
  data: null,
  total: 0,
  currentPage: 1,
  perPage: 20,
  count: null,
  filters: {
    blocked: false,
    shiftNow: true,
    name: "",
    phone: "",
    transports: [],
    cityDepended: false,
  },
};

export default function ordersReducer(state = initState, action) {
  switch (action.type) {
    case actions.SUCCESS_LOADING_COURIERS:
      return {
        ...state,
        data: action.data.couriers,
        total: action.data.total,
      };

    case actions.CHANGE_PAGE_COURIERS:
      return {
        ...state,
        currentPage: action.data,
      };

    case actions.CHANGE_NAME_COURIERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          name: action.data,
        },
      };
    case actions.CHANGE_BLOCKED_COURIERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          blocked: action.data,
        },
      };

    case actions.CHANGE_SHIFT_COURIERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          shiftNow: action.data,
        },
      };

    case actions.CHANGE_PHONE_COURIERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          phone: action.data,
        },
      };

    case actions.INIT_COURIERS_BY_PARAMS:
      return {
        ...state,
        count: !!action.data && !!action.data.count ? action.data.count : null,
        currentPage: !!action.data && !!action.data.page ? action.data.page : 1,
        filters: {
          ...state.filters,
          blocked: !!action.data && typeof action.data.blocked !== "undefined" ? action.data.blocked : null,
          shiftNow: !!action.data && typeof action.data.shiftNow !== "undefined" ? action.data.shiftNow : null,
          shiftNowByCourier:
            !!action.data && typeof action.data.shiftNowByCourier !== "undefined"
              ? action.data.shiftNowByCourier
              : null,
          name: !!action.data && !!action.data.name ? action.data.name : "",
          transports: !!action.data && !!action.data.transports ? action.data.transports : [],
          phone: !!action.data && !!action.data.phone ? action.data.phone : "",
          cityDepended: !!action.data && !!action.data.cityDepended ? action.data.cityDepended : false,
        },
      };

    case actions.CHANGE_TRANSPORTS_COURIERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          transports: action.data,
        },
      };

    case actions.RESET_STATE_COURIERS:
      return {
        ...initState,
      };

    case actions.FAIL_LOADING_COURIERS:
      return state;

    default:
      return state;
  }
}
