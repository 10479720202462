import actions from "./actions";

const initState = {
  data: null,
  total: 0,
  currentPage: 1,
  perPage: 20,
  count: null,
  filters: {
    blocked: false,
    shiftNow: true,
    name: "",
    place_id: null,
    cityDepended: false,
  },
};

export default function ordersReducer(state = initState, action) {
  switch (action.type) {
    case actions.SUCCESS_LOADING_SHOPPERS:
      return {
        ...state,
        data: action.data.shoppers,
        total: action.data.total,
      };

    case actions.CHANGE_PAGE_SHOPPERS:
      return {
        ...state,
        currentPage: action.data,
      };

    case actions.INIT_SHOPPERS_BY_PARAMS:
      return {
        ...state,
        count: !!action.data && !!action.data.count ? action.data.count : null,
        currentPage: !!action.data && !!action.data.page ? action.data.page : 1,
        filters: {
          ...state.filters,
          blocked: !!action.data && typeof action.data.blocked !== "undefined" ? action.data.blocked : null,
          shiftNow: !!action.data && typeof action.data.shiftNow !== "undefined" ? action.data.shiftNow : null,
          serviced_places: !!action.data && typeof action.data.place_id !== "undefined" ? action.data.place_id : null,
          name: !!action.data && !!action.data.name ? action.data.name : "",
          cityDepended: !!action.data && !!action.data.cityDepended ? action.data.cityDepended : false,
        },
      };

    case actions.RESET_STATE_SHOPPERS:
      return {
        ...initState,
      };

    case actions.FAIL_LOADING_SHOPPERS:
      return state;

    default:
      return state;
  }
}
