import React from "react";
import { useLocalStorage } from "react-use";

function getPreparedBrowserLanguage() {
  return navigator.language.split("-")[0];
}

const browserLanguage = getPreparedBrowserLanguage();

const localStorageKey = "language";

export function useLanguage() {
  const [localStorageLanguage] = useLocalStorage(localStorageKey);
  return localStorageLanguage || browserLanguage;
}

export function useLanguageSetter() {
  const currentLanguage = useLanguage();
  const [, setLocalStorageLanguage] = useLocalStorage(localStorageKey);

  return React.useCallback(
    (language) => {
      if (currentLanguage === language) return;
      setLocalStorageLanguage(language);
      window.location.reload();
    },
    [currentLanguage, setLocalStorageLanguage],
  );
}
