import React from "react";
import { Select } from "antd";

import { useLanguage, useLanguageSetter } from "../../helpers/language/useLanguage";
import { availableLanguages } from "../../constants/available-languages";

function LanguageSelector({ outerClassName }) {
  const language = useLanguage();
  const setLanguage = useLanguageSetter();

  return (
    <div className={outerClassName}>
      <Select value={language} onChange={setLanguage}>
        {availableLanguages.map((language) => (
          <Select.Option key={language} value={language}>
            {language.toUpperCase()}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default React.memo(LanguageSelector);
