import actions from "./actions";

const initState = {
  data: null,
  total: 0,
  currentPage: 1,
  perPage: 20,
  filters: {
    typeUser: "user",
    search: "",
    cityDepended: false,
  },
  isLoading: false,
};

export default function ordersReducer(state = initState, action) {
  switch (action.type) {
    case actions.START_LOADING_CLIENTS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SUCCESS_LOADING_CLIENTS:
      return {
        ...state,
        data: action.data.clients,
        total: action.data.total,
        isLoading: false,
      };

    case actions.INIT_CLIENTS_DATA:
      return {
        ...state,
        currentPage: action.payload.page,
        filters: {
          ...state.filters,
          search: action.payload.search,
          cityDepended: !!action.payload && !!action.payload.cityDepended ? action.payload.cityDepended : false,
        },
      };

    case actions.CHANGE_PAGE_CLIENTS:
      return {
        ...state,
        currentPage: action.data,
      };

    case actions.RESET_STATE_CLIENTS:
      return {
        ...initState,
      };

    case actions.CHANGE_SEARCH_CLIENTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.data,
        },
      };

    case actions.FAIL_LOADING_CLIENTS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
