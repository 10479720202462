import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

function generateParamsFromFilters(shoppersState, currentCity) {
  let filters = shoppersState.filters;
  let params = {
    provided_services: "shopper",
  };

  if (shoppersState.currentPage > 1) {
    params.offset = shoppersState.perPage * (shoppersState.currentPage - 1);
  }

  if (!!filters.name) {
    params.name = filters.name;
  }

  if (!!shoppersState.count) {
    params.count = shoppersState.count;
  }

  if (!!filters.serviced_places) {
    params.serviced_places = filters.serviced_places;
  }

  if (filters.shiftNow !== null) {
    params.on_work_shift = filters.shiftNow;
  }

  if (filters.cityDepended && !!currentCity && !!currentCity.id) {
    params.city = currentCity.id;
  }

  params.is_blocked = filters.blocked;
  return params;
}

export function* loadData() {
  yield takeEvery("START_LOADING_SHOPPERS", function* () {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    let params = generateParamsFromFilters(state.Shoppers, state.Auth.city);
    try {
      let shoppers = yield LoaderData.get(apiUrls.couriersList, params);
      if (shoppers.success) {
        let transfData = shoppers.data.map((item) => {
          return { ...item, key: item.id };
        });
        yield put({
          type: actions.SUCCESS_LOADING_SHOPPERS,
          data: {
            shoppers: transfData,
            total: shoppers.pagination.total,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_SHOPPERS,
          payload: shoppers,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_SHOPPERS,
        payload: e,
      });
    }
  });
}

export function* init() {
  yield takeEvery(actions.INIT_SHOPPERS, function* (action) {
    yield put({
      type: actions.INIT_SHOPPERS_BY_PARAMS,
      data: action.data,
    });

    yield put({
      type: actions.START_LOADING_SHOPPERS,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_SHOPPERS, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
    return;
  });
}

export default function* rootSaga() {
  yield all([fork(loadData), fork(init), fork(failLoading)]);
}
