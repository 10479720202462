import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import userpic from "../../image/user1.png";
import authAction from "../../redux/auth/actions";
import TopbarDropdownWrapper from "./topbarDropdown.style";

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <div className="isoDropdownLink" onClick={this.props.logout} href="# ">
          <IntlMessages id="topbar.logout" />
        </div>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className={"isoUserNameWrapper"}>
          <div className={"isoUserName"}>
            {!!this.props.userName ? this.props.userName : <IntlMessages id="common.no_name" />}
          </div>
          <div className="isoImgWrapper">
            <img alt="user" src={userpic} />
            <span className="userActivity online" />
          </div>
        </div>
      </Popover>
    );
  }
}

export default injectIntl(connect(null, { logout })(TopbarUser));
