import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../Page/Statistics")),
  },
  {
    path: "orders/active",
    component: asyncComponent(() => import("../Page/Orders/Orders")),
  },
  {
    path: "orders/history",
    component: asyncComponent(() => import("../Page/Orders/HistoryOrders")),
  },
  {
    path: "order/:id",
    exact: true,
    component: asyncComponent(() => import("../Page/Orders/Order")),
  },
  {
    path: "clients",
    exact: true,
    component: asyncComponent(() => import("../Page/Clients/Clients")),
  },
  {
    path: "clients/:id",
    exact: true,
    component: asyncComponent(() => import("../Page/Clients/Client")),
  },
  {
    path: "clients/:id/orders",
    exact: true,
    component: asyncComponent(() => import("../Page/Clients/ClientOrders")),
  },
  {
    path: "couriers",
    exact: true,
    component: asyncComponent(() => import("../Page/Couriers/Couriers")),
  },
  {
    path: "couriers/waitlist",
    exact: true,
    component: asyncComponent(() => import("../Page/Couriers/WaitingCouriers")),
  },
  {
    path: "couriers/map",
    exact: true,
    component: asyncComponent(() => import("../Page/Couriers/CouriersMap")),
  },
  {
    path: "couriers/list",
    exact: true,
    component: asyncComponent(() => import("../Page/Couriers/CouriersFullList")),
  },
  {
    path: "couriers/:id",
    exact: true,
    component: asyncComponent(() => import("../Page/Couriers/Courier")),
  },
  {
    path: "couriers/:id/orders",
    exact: true,
    component: asyncComponent(() => import("../Page/Couriers/CourierOrders")),
  },
  {
    path: "partners/all",
    exact: true,
    component: asyncComponent(() => import("../Page/Restaurants/PartnersAll")),
  },
  {
    path: "partners/hidden",
    exact: true,
    component: asyncComponent(() => import("../Page/Restaurants/PartnersHidden")),
  },
  {
    path: "partners/:id",
    exact: true,
    component: asyncComponent(() => import("../Page/Restaurants/Restaurant")),
  },
  {
    path: "partners/:id/orders",
    exact: true,
    component: asyncComponent(() => import("../Page/Restaurants/RestaurantOrders")),
  },
  {
    path: "citiesMap",
    exact: true,
    component: asyncComponent(() => import("../Page/CitiesMap")),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
