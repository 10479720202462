const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGIN_PRE_SUCCESS: "LOGIN_PRE_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  SET_SELF_INFO: "SET_SELF_INFO",
  LOAD_SELF_INFO: "LOAD_SELF_INFO",
  ERROR_SELF_INFO: "ERROR_SELF_INFO",
  CHECK_ACCESS_TOKEN: "CHECK_ACCESS_TOKEN",
  SET_ACCOUNT_CITY_SAGA: "SET_ACCOUNT_CITY_SAGA",
  SET_ACCOUNT_CITY: "SET_ACCOUNT_CITY",
  AUTH_SSO_REQUEST: "AUTH_SSO_REQUEST",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  checkAccessToken: () => ({ type: actions.CHECK_ACCESS_TOKEN }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  getSelfInfo: () => ({
    type: actions.LOAD_SELF_INFO,
  }),
  selectCity: (city, needRedirect = false) => ({
    type: actions.SET_ACCOUNT_CITY_SAGA,
    data: city,
    needRedirect: needRedirect,
  }),
  getSSOToken: () => ({
    type: actions.AUTH_SSO_REQUEST,
  }),
};
export default actions;
