import actions from "./actions";

const initState = {
  data: null,
  primaryData: null,
};

export default function restaurantReducer(state = initState, action) {
  switch (action.type) {
    case actions.SUCCESS_LOADING_RESTAURANT:
      return {
        ...initState,
        data: action.data.restaurant,
        primaryData: action.data.restaurant,
      };

    case actions.RESET_STATE_RESTAURANT:
      return {
        ...initState,
      };

    case actions.CHANGE_INSTRUCTION:
      return {
        ...state,
        data: {
          ...state.data,
          courier_instruction: action.payload,
        },
      };

    case actions.FAIL_LOADING_RESTAURANT:
      return state;

    default:
      return state;
  }
}
