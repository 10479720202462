import React from "react";
import styled from "styled-components";
import { Button } from "antd";

class NotificationBody extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <div>{this.props.message}</div>
        {!!this.props.buttonText && (
          <div className={"buttonToRight"}>
            <Button type="primary" onClick={this.props.onClickButtonHandler}>
              {this.props.buttonText}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const styledNotificationBody = styled(NotificationBody)`
  .buttonToRight {
    text-align: right;
  }
`;

export default styledNotificationBody;
