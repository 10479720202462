import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import clone from "clone";

import LanguageSelector from "../LanguageSelector";
import IntlMessages from "../../components/utility/intlMessages";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import Logo from "../../components/utility/logo";

import appActions from "../../redux/app/actions";
import options from "./options";

import SidebarWrapper from "./sidebar.style";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } = appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find((key) => !(app.openKeys.indexOf(key) > -1));
    const latestCloseKey = app.openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { path, link, label, leftIcon, rightIcon, children } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={path}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children.map((child) => {
            const linkTo = child.withoutDashboard ? `/${child.path}` : `${url}/${child.path}`;
            return (
              <Menu.Item style={submenuStyle} key={child.path}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return path ? (
      <Menu.Item key={path}>
        <Link to={`${url}/${path}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    ) : (
      <Menu.Item key={link}>
        <a href={link} key={link}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
            {rightIcon && <i className={rightIcon} style={{ fontSize: "14px", marginLeft: "10px" }} />}
          </span>
        </a>
      </Menu.Item>
    );
  };
  render() {
    const {
      app,
      toggleOpenDrawer,
      height,
      theme: { sidebar: sidebarTheme },
    } = this.props;

    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: sidebarTheme.backgroundColor,
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: sidebarTheme.textColor,
    };
    const submenuColor = {
      color: sidebarTheme.textColor,
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map((singleOption) =>
                this.getMenuItem({
                  submenuStyle,
                  submenuColor,
                  singleOption,
                }),
              )}
            </Menu>
          </Scrollbars>
          <LanguageSelector outerClassName="language-selector" />
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default injectIntl(
  withTheme(
    connect((state) => ({ app: state.App, height: state.App.height }), {
      toggleOpenDrawer,
      changeOpenKeys,
      changeCurrent,
      toggleCollapsed,
    })(Sidebar),
  ),
);
