import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import moment from "moment";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import pathToReg from "path-to-regexp";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from "../../helpers/globalEventBus";

function transformData(data) {
  const indexMapper = (item, index) => ({ ...item, key: index });
  let transfData = {
    ...data,
    active_orders: data.active_orders.map(indexMapper),
    orders_estimated: data.orders_estimated ? data.orders_estimated.map(indexMapper) : null,
  };
  transfData.comment = !!transfData.comment ? transfData.comment : "";
  return transfData;
}

export function* loadData() {
  yield takeEvery("START_LOADING_COURIER", function* (action) {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }
      let url = pathToReg.compile(apiUrls.courierOne);
      let courier = yield LoaderData.get(
        url({
          id: action.data,
        }),
      );
      if (courier.success) {
        let transfData = transformData(courier.data);
        yield put({
          type: actions.SUCCESS_LOADING_COURIER,
          data: {
            courier: transfData,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_COURIER,
          payload: courier,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_COURIER,
        payload: e,
      });
    }
  });
}

export function* init() {
  yield takeEvery(actions.INIT_COURIER, function* ({ payload }) {
    yield put({
      type: actions.START_LOADING_COURIER,
      data: payload,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_COURIER, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, {
      id: "common.error",
      desc: payload.message || payload.error.message,
    });
  });
}

export function* changeWorkShift() {
  yield takeEvery(actions.SAVE_DATA_COURIER, function* ({ payload }) {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    let params = {
      courier_id: payload.courier_id,
      on_work_shift: !payload.on_work_shift,
    };

    params["work_shift_ends_at"] = payload.work_shift_ends_at
      ? moment(payload.work_shift_ends_at).format("HH:mm")
      : null;

    let courier = yield LoaderData.post(apiUrls.courierUpdate, params);
    if (courier.success) {
      yield globalEventBus.emit(SUCCESS_NOTIFICATION, { id: "common.success" });
      let transfData = transformData(courier.data);
      yield put({
        type: actions.SUCCESS_LOADING_COURIER,
        data: {
          courier: transfData,
        },
      });
      return;
    }

    if (courier.status === 409) {
      const state = yield select();
      yield globalEventBus.emit(ERROR_NOTIFICATION, {
        id: "common.server_error",
        desc: "courier.card.edit_error.description",
      });
      yield put({
        type: actions.INIT_COURIER,
        payload: state.Courier.primaryData.id,
      });
      return;
    }

    yield put({
      type: actions.FAIL_LOADING_COURIER,
      payload: courier,
    });

    return;
  });
}

export function* toggleIncludeInAutoAssignment() {
  yield takeEvery(actions.TOGGLE_INCLUDE_IN_AUTO_ASSIGNMENT, function* () {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    const params = {
      courier_id: state.Courier.data.id,
      include_in_auto_assign: !state.Courier.data.include_in_auto_assign,
    };
    const courier = yield LoaderData.post(apiUrls.courierUpdate, params);
    if (courier.success) {
      yield put({
        type: actions.SUCCESS_LOADING_COURIER,
        data: { courier: transformData(courier.data) },
      });
      return;
    }

    yield put({
      type: actions.FAIL_LOADING_COURIER,
      payload: courier,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(loadData),
    fork(init),
    fork(changeWorkShift),
    fork(failLoading),
    fork(toggleIncludeInAutoAssignment),
  ]);
}
