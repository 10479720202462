import axios from "axios";
import { all, takeEvery, put, fork } from "redux-saga/effects";

import actions from "./actions";

export function* sheetsRequest() {
  yield takeEvery(actions.SHEETS_REQUEST, function* ({ accessToken, notifySheetsDataError }) {
    try {
      const data = yield axios({
        method: "GET",
        url: "https://sheets.googleapis.com/v4/spreadsheets/1WpMkAiiRq305B_pMwzRoDYRmX305etkBi1PFw9XyCZ8/values/A1:H1000",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      }).then((res) =>
        res.data.values
          .slice(1)
          .map(([city, latitude, longitude, status, dateOpen, franchise, orderProfit, profit]) => ({
            city,
            latitude,
            longitude,
            status,
            dateOpen,
            franchise,
            orderProfit,
            profit,
          }))
          .filter((object) => object.city !== ""),
      );

      yield put({
        type: actions.SHEETS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      notifySheetsDataError();
      yield put({
        type: actions.SHEETS_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(sheetsRequest)]);
}
