const actions = {
  START_LOADING_PARTNERS: "START_LOADING_PARTNERS",
  SUCCESS_LOADING_PARTNERS: "SUCCESS_LOADING_PARTNERS",
  FAIL_LOADING_PARTNERS: "FAIL_LOADING_PARTNERS",
  CHANGE_PAGE_PARTNERS: "CHANGE_PAGE_PARTNERS",
  RESET_STATE_PARTNERS: "RESET_STATE_PARTNERS",
  CHANGE_PAGE_ASYNC_PARTNERS: "CHANGE_PAGE_ASYNC_PARTNERS",
  INIT_PARTNERS: "INIT_PARTNERS",
  INIT_PARTNERS_DATA: "INIT_PARTNERS_DATA",
  CHANGE_SEARCH_PARTNERS: "CHANGE_SEARCH_PARTNERS",
  CHANGE_FILTERS_PARTNERS: "CHANGE_FILTERS_PARTNERS",
  init: (initData) => ({
    type: actions.INIT_PARTNERS,
    payload: initData,
  }),
  resetState: () => ({
    type: actions.RESET_STATE_PARTNERS,
  }),
  changePage: (page) => ({
    type: actions.CHANGE_PAGE_ASYNC_PARTNERS,
    payload: page,
  }),
  changeFilter: (data, actionName) => ({
    type: actions.CHANGE_FILTERS_PARTNERS,
    payload: {
      name: actionName,
      data: data,
    },
  }),
};
export default actions;
