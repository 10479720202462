import actions from "./actions";
import jwt_decode from "jwt-decode";

const initState = {
  idToken: null,
  error: {},
  selfData: null,
  token: null,
  sso: null,
  tokenData: null,
  city: null,
  loading: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.LOGIN_SUCCESS:
      const accessToken = jwt_decode(action.token.token);
      return {
        ...state,
        idToken: action.token,
        token: action.token.token,
        tokenData: accessToken,
        loading: false,
      };
    case actions.LOGOUT_SUCCESS:
      return initState;
    case actions.SET_SELF_INFO:
      return {
        ...state,
        selfData: action.data,
      };
    case actions.SET_ACCOUNT_CITY:
      return {
        ...state,
        city: action.data,
      };
    case actions.AUTH_SSO_SUCCESS:
      return {
        ...state,
        sso: action.payload,
      };
    default:
      return state;
  }
}
