import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { withTheme } from "styled-components";

import appActions from "../../redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import SwitchCitySelector from "../../usedContainers/SwitchCitySelector";
import LanguageSwitcher from "../LanguageSelector";

const { Header } = Layout;
const { toggleCollapsed } = appActions;

function Topbar({ collapsed: collapsedProp, userName, theme: { topbar: topbarTheme }, openDrawer, toggleCollapsed }) {
  const collapsed = collapsedProp && !openDrawer;
  const styling = {
    background: topbarTheme.backgroundColor,
    position: "fixed",
    width: "100%",
    height: 70,
  };

  return (
    <TopbarWrapper>
      <Header style={styling} className={collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"}>
        <div className="isoLeft">
          <button
            className={collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"}
            style={{ color: topbarTheme.textColor }}
            onClick={toggleCollapsed}
          />
        </div>

        <ul className="isoRight">
          <li className="isoUser">
            <SwitchCitySelector />
          </li>
          <li className="isoUser">
            <TopbarUser userName={userName} />
          </li>
        </ul>
      </Header>
    </TopbarWrapper>
  );
}

export default withTheme(connect((state) => ({ ...state.App }), { toggleCollapsed })(Topbar));
