const actions = {
  START_LOADING_ORDERS: "START_LOADING_ORDERS",
  SUCCESS_LOADING_ORDERS: "SUCCESS_LOADING_ORDERS",
  FAIL_LOADING_ORDERS: "FAIL_LOADING_ORDERS",
  CHANGE_FILTERS_ORDERS: "CHANGE_FILTERS_ORDERS",
  CHANGE_FILTER_TS_FROM_ORDERS: "CHANGE_FILTER_TS_FROM_ORDERS",
  CHANGE_FILTER_TS_TO_ORDERS: "CHANGE_FILTER_TS_TO_ORDERS",
  CHANGE_FILTER_STATES_ORDERS: "CHANGE_FILTER_STATES_ORDERS",
  INIT_TABLE_BY_PARAMS: "INIT_TABLE_BY_PARAMS",
  CHANGE_PAGE_ORDERS: "CHANGE_PAGE_ORDERS",
  CHANGE_PAGE_ASYNC_ORDERS: "CHANGE_PAGE_ASYNC_ORDERS",
  RESET_STATE_ORDERS: "RESET_STATE_ORDERS",
  CHANGE_SEARCH_PLACE_ORDERS: "CHANGE_SEARCH_PLACE_ORDERS",
  LOAD_PLACES_ORDERS: "LOAD_PLACES_ORDERS",
  SET_LOAD_PLACES_ORDERS: "SET_LOAD_PLACES_ORDERS",
  SET_PLACES_IN_FILTERS_ORDERS: "SET_PLACES_IN_FILTERS_ORDERS",
  CHANGE_FILTER_NUMBER_ORDERS: "CHANGE_FILTER_NUMBER_ORDERS",
  INIT_ORDERS: "INIT_ORDERS",
  init: (params) => ({
    type: actions.INIT_ORDERS,
    payload: params,
  }),
  changeFilter: (data, actionName) => ({
    type: actions.CHANGE_FILTERS_ORDERS,
    payload: {
      name: actionName,
      data: data,
    },
  }),
  resetState: () => ({
    type: actions.RESET_STATE_ORDERS,
  }),
  changePage: (page) => ({
    type: actions.CHANGE_PAGE_ASYNC_ORDERS,
    payload: page,
  }),
  changeSearchPlace: (search) => ({
    type: actions.CHANGE_SEARCH_PLACE_ORDERS,
    payload: search,
  }),
};
export default actions;
