const siteConfig = {
  siteName: `${process.env.__CONFIG__.copyright} Backoffice`,
  siteIcon: "ion-flash",
  footerText: `${process.env.__CONFIG__.copyright} ©${new Date().getFullYear()}`,
};

const apiUrls = {
  rootApiUrl: process.env.__CONFIG__.apiHost,
  // rootApiUrl: 'http://localhost:8010/proxy/v1.3',
  authUrl: "/auth/manager",
  authSSO: "/auth/sso",
  refreshTokenUrl: "/auth/refresh",
  ordersList: "/manager/admin/order/list",
  placesList: "/manager/admin/place/list",
  clientsList: "/manager/admin/client/list",
  couriersList: "/manager/admin/courier/list",
  clientOne: "/manager/admin/client/:id",
  clientUpdate: "/manager/admin/client/update",
  courierOne: "/manager/admin/courier/:id",
  courierUpdate: "/manager/admin/courier/update",
  orderOne: "/manager/admin/order/:id",
  orderFinishShopper: "/manager/admin/order/:id/finish-shopper",
  orderUpdate: "/manager/admin/order/update",
  orderCompositionUpdate: "/manager/admin/order/:id/update.services",
  restaurantOne: "/manager/admin/place/:id",
  restaurantUpdate: "/manager/admin/place/update",
  statistics: "/manager/admin/statistic",
  city: "/manager/admin/city/:id",
  orderTracks: "/manager/admin/order/:id/tracks",
  selfInfo: "/manager/admin/self",
  promocodeCreate: "/manager/admin/promocode/create",
  centrifugeSubscribe: "/auth/centrifugo/subscribe",
  centrifugeRefresh: "/auth/centrifugo/refresh",
  checkCoordinates: "/manager/admin/coordinates",
};

const apiUrlsOptimate = {
  rootApiUrl: process.env.__CONFIG__.optimateHost,
  courierRoute: "/v1/routes/:id",
};

const fingerprintOptions = {
  fonts: {
    extendedJsFonts: true,
  },
};

export { siteConfig, apiUrls, apiUrlsOptimate, fingerprintOptions };
