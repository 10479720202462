import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import pathToReg from "path-to-regexp";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, SUCCESS_NOTIFICATION, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

function transformData(data) {
  const indexMapper = (item, index) => ({ ...item, key: index });
  let transfData = {
    ...data,
    active_orders: data.active_orders.map(indexMapper),
    orders_estimated: data.orders_estimated ? data.orders_estimated.map(indexMapper) : null,
  };
  transfData.comment = !!transfData.comment ? transfData.comment : "";
  return transfData;
}

export function* loadData() {
  yield takeEvery("START_LOADING_CLIENT", function* (action) {
    try {
      let url = pathToReg.compile(apiUrls.clientOne);
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      let client = yield LoaderData.get(
        url({
          id: action.data,
        }),
      );
      if (client.success) {
        let transfData = transformData(client.data);
        yield put({
          type: actions.SUCCESS_LOADING_CLIENT,
          data: {
            client: transfData,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_CLIENT,
          payload: client,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_CLIENT,
        payload: e,
      });
    }
  });
}

export function* init() {
  yield takeEvery(actions.INIT_CLIENT, function* ({ payload }) {
    yield put({
      type: actions.START_LOADING_CLIENT,
      data: payload,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_CLIENT, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export function* save() {
  yield takeEvery(actions.SAVE_DATA_CLIENT, function* () {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    let params = {
      client_id: state.Client.data.id,
      comment: state.Client.data.comment,
    };
    let client = yield LoaderData.post(apiUrls.clientUpdate, params);
    if (client.success) {
      yield globalEventBus.emit(SUCCESS_NOTIFICATION, { id: "common.success" });
      let transfData = transformData(client.data);
      yield put({
        type: actions.SUCCESS_LOADING_CLIENT,
        data: {
          client: transfData,
        },
      });
      return;
    }
    if (client.status === 409) {
      const state = yield select();
      yield globalEventBus.emit(ERROR_NOTIFICATION, {
        id: "common.server_error",
        desc: "client.card.edit_error.description",
      });
      yield put({
        type: actions.INIT_CLIENT,
        payload: state.Client.primaryData.id,
      });
      return;
    }

    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.server_error" });
  });
}

export default function* rootSaga() {
  yield all([fork(loadData), fork(init), fork(save), fork(failLoading)]);
}
