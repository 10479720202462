const actions = {
  START_LOADING_COURIER: "START_LOADING_COURIER",
  SUCCESS_LOADING_COURIER: "SUCCESS_LOADING_COURIER",
  FAIL_LOADING_COURIER: "FAIL_LOADING_COURIER",
  RESET_STATE_COURIER: "RESET_STATE_COURIER",
  INIT_COURIER: "INIT_COURIER",
  SAVE_DATA_COURIER: "SAVE_DATA_COURIER",
  TOGGLE_INCLUDE_IN_AUTO_ASSIGNMENT: "TOGGLE_INCLUDE_IN_AUTO_ASSIGNMENT",
  init: (id) => ({
    type: actions.INIT_COURIER,
    payload: id,
  }),
  resetState: () => ({
    type: actions.RESET_STATE_COURIER,
  }),
  changeWorkShift: ({ courier_id, on_work_shift, work_shift_ends_at }) => ({
    type: actions.SAVE_DATA_COURIER,
    payload: {
      courier_id,
      on_work_shift,
      work_shift_ends_at,
    },
  }),
  toggleIncludeInAutoAssignment: () => ({
    type: actions.TOGGLE_INCLUDE_IN_AUTO_ASSIGNMENT,
  }),
};
export default actions;
