import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { Select } from "antd";
import authActions from "../../redux/auth/actions";

const { selectCity } = authActions;

const Option = Select.Option;

class Component extends React.Component {
  onChangeHandler(cityId) {
    const findedCity =
      cityId === "-"
        ? this.props.items.find((item) => item.id === "")
        : this.props.items.find((item) => item.id === cityId);
    this.props.selectCity(findedCity, true);
  }

  generateOptions() {
    return this.props.items.map((item) => {
      return (
        <Option key={item.id || "-"}>
          {item.name || this.props.intl.formatMessage({ id: "header.city_select.all_cities" })}
        </Option>
      );
    });
  }

  render() {
    const { items, choosedCity, className } = this.props;
    if (Array.isArray(items) && !!items.length && !!choosedCity) {
      return (
        <div className={className}>
          <Select
            style={{ width: "200px" }}
            value={choosedCity.id || "-"}
            dropdownMatchSelectWidth={true}
            onChange={(cityId) => this.onChangeHandler(cityId)}
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder={this.props.intl.formatMessage({
              id: "common.select",
            })}
          >
            {this.generateOptions()}
          </Select>
        </div>
      );
    }
    return null;
  }
}

const ConnectedComponent = injectIntl(
  connect(
    (state) => ({
      items: state.Auth.selfData.cities || [],
      choosedCity: state.Auth.city,
    }),
    {
      selectCity,
    },
  )(Component),
);

const StyledConnectedComponent = styled(ConnectedComponent)`
  position: relative;

  .ant-select-arrow {
    display: none;
  }

  li {
    margin-right: 0 !important;
    width: 100%;
  }
`;

export default StyledConnectedComponent;
