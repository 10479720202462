import Auth from "./auth/reducer";
import App from "./app/reducer";
import Orders from "./orders/reducer";
import Clients from "./clients/reducer";
import Client from "./client/reducer";
import Couriers from "./couriers/reducer";
import Shoppers from "./shoppers/reducer";
import Courier from "./courier/reducer";
import Order from "./order/reducer";
import Restaurant from "./restaurant/reducer";
import Statistics from "./statistics/reducer";
import City from "./city/reducer";
import CitiesMap from "./citiesMap/reducer";
import Partners from "./partners/reducer";

export default {
  Auth,
  App,
  Orders,
  Clients,
  Client,
  Couriers,
  Shoppers,
  Courier,
  Order,
  Restaurant,
  Statistics,
  City,
  CitiesMap,
  Partners,
};
