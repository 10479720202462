import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

function generateParamsFromFilters(couriersState, currentCity) {
  let filters = couriersState.filters;
  let params = {};

  if (couriersState.currentPage > 1) {
    params.offset = couriersState.perPage * (couriersState.currentPage - 1);
  }

  if (!!filters.name) {
    params.name = filters.name;
  }

  if (!!couriersState.count) {
    params.count = couriersState.count;
  }

  if (!!filters.phone) {
    params.phone = filters.phone;
  }

  if (!!filters.transports.length > 0) {
    params.types = filters.transports;
  }

  if (filters.shiftNow !== null) {
    params.on_work_shift = filters.shiftNow;
  }

  if (filters.shiftNowByCourier !== null) {
    params.on_work_shift_by_courier = filters.shiftNowByCourier;
  }

  if (filters.cityDepended && !!currentCity && !!currentCity.id) {
    params.city = currentCity.id;
  }

  params.is_blocked = filters.blocked;
  return params;
}

export function* loadData() {
  yield takeEvery("START_LOADING_COURIERS", function* () {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    let params = generateParamsFromFilters(state.Couriers, state.Auth.city);
    try {
      let couriers = yield LoaderData.get(apiUrls.couriersList, params);
      if (couriers.success) {
        let transfData = couriers.data.map((item) => {
          return { ...item, key: item.id };
        });
        yield put({
          type: actions.SUCCESS_LOADING_COURIERS,
          data: {
            couriers: transfData,
            total: couriers.pagination.total,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_COURIERS,
          payload: couriers,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_COURIERS,
        payload: e,
      });
    }
  });
}

export function* changeFilter() {
  yield takeEvery(actions.CHANGE_FILTERS_COURIERS, function* ({ payload }) {
    yield put({
      type: payload.name,
      data: payload.data,
    });

    yield put({
      type: actions.CHANGE_PAGE_COURIERS,
      data: 1,
    });

    yield put({
      type: actions.START_LOADING_COURIERS,
    });
  });
}

export function* changePage() {
  yield takeEvery(actions.CHANGE_PAGE_ASYNC_COURIERS, function* ({ payload }) {
    yield put({
      type: actions.CHANGE_PAGE_COURIERS,
      data: payload,
    });

    yield put({
      type: actions.START_LOADING_COURIERS,
    });
  });
}

export function* init() {
  yield takeEvery(actions.INIT_COURIERS, function* ({ payload }) {
    yield put({
      type: actions.INIT_COURIERS_BY_PARAMS,
      data: payload,
    });
    yield put({
      type: actions.START_LOADING_COURIERS,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_COURIERS, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export default function* rootSaga() {
  yield all([fork(loadData), fork(changeFilter), fork(init), fork(changePage), fork(failLoading)]);
}
