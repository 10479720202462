import React from "react";
import { Provider } from "react-redux";
import Helmet from "react-helmet";
import { ThemeProvider } from "styled-components";
import moment from "moment";
import "moment/locale/ru";
import { MapsConfigProvider } from "@bb/components";

import { getPublicSiteImageUrl } from "./helpers/getPublicSiteUrl";
import themes from "./settings/themes";
import DashAppHolder, { GlobalStyles } from "./DashApp/dashAppStyle";
import { store } from "./redux/store";
import DashApp from "./DashApp/DashApp";
import Boot from "./redux/boot";
import { useLanguage } from "./helpers/language/useLanguage";

moment.locale("ru");
Boot();

function RootApp() {
  const language = useLanguage();
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" sizes="32x32" href={getPublicSiteImageUrl("favicon.png")} />
      </Helmet>
      <ThemeProvider theme={themes.broniboy}>
        <GlobalStyles />
        <Provider store={store}>
          <DashAppHolder>
            <MapsConfigProvider
              mapsProvider={process.env.__CONFIG__.mapsProvider}
              geocoderProvider={process.env.__CONFIG__.geocoderProvider}
              mapsLanguage={language}
              geocoderLanguage={language}
              googleApiKey={process.env.__CONFIG__.googleApiKey}
              yandexApiKey={process.env.__CONFIG__.yandexApiKey}
            >
              <DashApp />
            </MapsConfigProvider>
          </DashAppHolder>
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default RootApp;
