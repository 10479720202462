import actions from "./actions";

const initState = {
  data: null,
  total: 0,
  currentPage: 1,
  perPage: 20,
  network: "",
  filters: {
    hidden: false,
    name: "",
  },
};

export default function ordersReducer(state = initState, action) {
  switch (action.type) {
    case actions.SUCCESS_LOADING_PARTNERS:
      return {
        ...state,
        data: action.data.partners,
        total: action.data.total,
      };

    case actions.INIT_PARTNERS_DATA:
      return {
        ...state,
        currentPage: action.payload.page,
        network: action.payload.network,
        filters: {
          ...state.filters,
          hidden: !!action.payload.hidden ? action.payload.hidden : false,
          name: !!action.payload.name ? action.payload.name : "",
        },
      };

    case actions.CHANGE_PAGE_PARTNERS:
      return {
        ...state,
        currentPage: action.data,
      };

    case actions.RESET_STATE_PARTNERS:
      return {
        ...initState,
      };

    case actions.CHANGE_SEARCH_PARTNERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          name: action.data,
        },
      };

    case actions.FAIL_LOADING_PARTNERS:
      return state;

    default:
      return state;
  }
}
