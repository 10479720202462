const actions = {
  LOGIN: "GOOGLE_LOGIN",
  LOGOUT: "GOOGLE_LOGOUT",

  SHEETS_REQUEST: "GOOGLE_SHEETS_REQUEST",
  SHEETS_SUCCESS: "GOOGLE_SHEETS_SUCCESS",
  SHEETS_ERROR: "GOOGLE_SHEETS_ERROR",

  login: (payload) => ({
    type: actions.LOGIN,
    payload: payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),

  fetchSheetsData: ({ accessToken, notifySheetsDataError }) => ({
    type: actions.SHEETS_REQUEST,
    accessToken,
    notifySheetsDataError,
  }),
};
export default actions;
