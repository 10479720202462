import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import pathToReg from "path-to-regexp";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

export function* loadData() {
  yield takeEvery(actions.START_LOADING_CITY, function* (action) {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      let url = pathToReg.compile(apiUrls.city);
      let city = yield LoaderData.get(
        url({
          id: action.data,
        }),
      );
      if (city.success) {
        yield put({
          type: actions.SUCCESS_LOADING_CITY,
          data: {
            city: city.data,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_CITY,
          payload: city,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_CITY,
        payload: e,
      });
    }
  });
}

export function* init() {
  yield takeEvery(actions.INIT_CITY, function* ({ payload }) {
    yield put({
      type: actions.START_LOADING_CITY,
      data: payload,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_CITY, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export default function* rootSaga() {
  yield all([fork(loadData), fork(init), fork(failLoading)]);
}
