import React from "react";
import moment from "moment";
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import { push } from "react-router-redux";
import Fingerprint2 from "fingerprintjs2";
import { all, takeEvery, put, fork, call, select, cancel } from "redux-saga/effects";

import { getSavedCityId, saveCityId, removeCityId } from "./helpers";
import { getToken, clearToken } from "../../helpers/utility";
import { fingerprintOptions } from "../../settings";
import LoaderData from "../../helpers/api";
import { apiUrls } from "../../settings";
import appActions from "../app/actions";
import actions from "./actions";
import { globalEventBus, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

const BRONIBOY_JWT_CODE = "ojashg21BB";

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* (action) {
    try {
      let payload = action.payload;
      let fp = yield Fingerprint2.getPromise(fingerprintOptions).then((components) => {
        let values = components.map((component) => component.value);
        return Fingerprint2.x64hash128(values.join(""), 31);
      });
      payload = {
        ...payload,
        platform: "web",
        device_id: fp,
      };
      let data = yield LoaderData.post(apiUrls.authUrl, payload, false);
      if (data.success) {
        yield put({
          type: actions.LOGIN_PRE_SUCCESS,
          payload: data.data,
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR, payload: data });
      }
    } catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginPreSuccess() {
  yield takeEvery(actions.LOGIN_PRE_SUCCESS, function* (action) {
    localStorage.setItem("JWT", jwt.sign(action.payload, BRONIBOY_JWT_CODE));
    yield put({
      type: actions.LOGIN_SUCCESS,
      token: action.payload,
    });
  });
}

export function* loginSSO() {
  yield takeEvery(actions.AUTH_SSO_REQUEST, function* () {
    try {
      yield checkAccessToken();
      const answer = yield LoaderData.get(apiUrls.authSSO);
      if (answer.url) {
        window.location.replace(answer.url);
      } else {
        yield globalEventBus.emit(ERROR_NOTIFICATION);
        yield put(push("/"));
      }
    } catch (e) {
      yield yield globalEventBus.emit(ERROR_NOTIFICATION);
      yield put(push("/"));
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* (action) {
    const message = !!action.payload && !!action.payload.error ? action.payload.error.message : undefined;
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: typeof message === "string" ? message : "common.error" });
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, logoutFunc);
}

function* logoutFunc() {
  clearToken();
  yield put({
    type: actions.LOGOUT_SUCCESS,
  });
  yield put(push("/"));
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken();
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
      });
    }
  });
}

export function* checkAccessToken() {
  try {
    const token = getToken();
    const accessToken = jwt_decode(token.token);
    const expTime = moment(accessToken.exp * 1000);
    const diff = expTime.diff(moment(), "seconds");
    if (diff > 60) return;
    yield call(refreshAccessToken, token.refresh_token);
  } catch (e) {
    yield call(logoutFunc);
  }
}

export function* checkAccessSaga() {
  yield takeEvery(actions.CHECK_ACCESS_TOKEN, checkAccessToken);
}

let requestRefresh = null;

function* refreshAccessToken(refreshToken) {
  try {
    const token = refreshToken;
    if (!requestRefresh) {
      requestRefresh = LoaderData.refreshToken(apiUrls.refreshTokenUrl, token);
    }
    let refreshedTokens = yield requestRefresh;
    if (refreshedTokens.status === false) {
      throw new Error();
    }

    if (refreshedTokens.success === false) {
      throw new Error();
    }
    yield call(setRefreshAccessToken, refreshedTokens.data);
  } catch (e) {
    yield call(logoutFunc);
  } finally {
    requestRefresh = null;
  }
}

function* setRefreshAccessToken(data) {
  localStorage.setItem("JWT", jwt.sign(data, BRONIBOY_JWT_CODE));
  yield put({
    type: actions.LOGIN_SUCCESS,
    token: data,
  });
}

export function* loadManagerSelfData() {
  yield takeEvery(actions.LOAD_SELF_INFO, function* () {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      const manager = yield LoaderData.get(apiUrls.selfInfo);
      manager.data.cities.sort((city1, city2) => (city1.name.toLowerCase() > city2.name.toLowerCase() ? 1 : -1));
      manager.data.cities.splice(0, 0, { id: "", name: "" });

      if (manager.success) {
        yield put({
          type: actions.SET_SELF_INFO,
          data: manager.data,
        });
        yield call(initCity);
      } else {
        yield put({
          type: actions.ERROR_SELF_INFO,
          payload: manager,
        });
      }
    } catch (e) {
      yield put({
        type: actions.ERROR_SELF_INFO,
        payload: e,
      });
    }
  });
}

export function* failLoading() {
  yield takeEvery(actions.ERROR_SELF_INFO, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export function* initCity() {
  try {
    const state = yield select();
    const selfData = state.Auth.selfData;
    let cityIdFromLS = getSavedCityId(selfData.id);

    if (!cityIdFromLS) {
      //если небыло ид в локалсторе, берем первый из массива, если он есть.
      if (Array.isArray(selfData.cities) && !!selfData.cities.length) {
        const firstCity = selfData.cities[0];
        yield put({
          type: actions.SET_ACCOUNT_CITY_SAGA,
          data: firstCity,
          needRedirect: false,
        });
      }
    } else {
      //если был ид в локалсторе
      if (Array.isArray(selfData.cities) && !!selfData.cities.length) {
        //если массив городов пришел
        const findedCity = selfData.cities.find((item) => item.id === cityIdFromLS);
        if (!!findedCity) {
          //если нашли город с ид из локалсторы в массиве городов
          yield put({
            type: actions.SET_ACCOUNT_CITY_SAGA,
            data: findedCity,
            needRedirect: false,
          });
        } else {
          //если не нашли город с ид из локалсторы в массиве городов то берем первый
          const firstCity = selfData.cities[0];
          yield put({
            type: actions.SET_ACCOUNT_CITY_SAGA,
            data: firstCity,
            needRedirect: false,
          });
        }
      } else {
        //если был выбран раньше, а сейчас нет
        removeCityId(selfData.id);
      }
    }
  } catch (e) {}
}

export function* chooseCity() {
  yield takeEvery(actions.SET_ACCOUNT_CITY_SAGA, function* (action) {
    try {
      const state = yield select();
      const selfData = state.Auth.selfData;

      const city = action.data;

      saveCityId(selfData.id, city.id);

      yield put({
        type: actions.SET_ACCOUNT_CITY,
        data: city,
      });

      if (action.needRedirect) {
        yield put({
          type: appActions.CLEAR_MENU,
        });
        yield put(push("/dashboard"));
      }
    } catch (e) {
      yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "user.change_city.error_notification.description" });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSSO),
    fork(loginPreSuccess),
    fork(loginError),
    fork(logout),
    fork(loadManagerSelfData),
    fork(failLoading),
    fork(checkAccessSaga),
    fork(chooseCity),
  ]);
}
