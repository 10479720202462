import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

function generateParams(statisticsState, currentCity) {
  let params = {};

  if (statisticsState.date_start) {
    params.date_start = statisticsState.date_start;
  }

  if (statisticsState.date_finish) {
    params.date_finish = statisticsState.date_finish;
  }

  if (statisticsState.cityDepended && !!currentCity && !!currentCity.id) {
    params.city = currentCity.id;
  }

  return params;
}

export function* loadData() {
  yield takeEvery(actions.START_LOADING_STATISTICS, function* () {
    try {
      yield call(checkAccessToken);
      let state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      let params = generateParams(state.Statistics, state.Auth.city);

      let stats = yield LoaderData.get(apiUrls.statistics, params);
      if (stats.success) {
        yield put({
          type: actions.SUCCESS_LOADING_STATISTICS,
          data: {
            restaurant: stats.data,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_STATISTICS,
          payload: stats,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_STATISTICS,
        payload: e,
      });
    }
  });
}

export function* init() {
  yield takeEvery(actions.INIT_STATISTICS, function* ({ payload }) {
    yield put({
      type: actions.INIT_STATISTICS_BY_PARAMS,
      data: payload,
    });

    yield put({
      type: actions.START_LOADING_STATISTICS,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_STATISTICS, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export function* changeFilters() {
  yield takeEvery(actions.CHANGE_FILTERS_STATISTICS, function* ({ payload }) {
    yield put({
      type: actions.UPDATE_STATE_BY_TEXT_STATISTICS,
      data: payload,
    });

    yield put({
      type: actions.START_LOADING_STATISTICS,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(loadData), fork(init), fork(failLoading), fork(changeFilters)]);
}
