const LS_OBJ_NAME = "_usersData";

function getSavedCityId(userId) {
  try {
    const usersDataFromLS = JSON.parse(localStorage.getItem(LS_OBJ_NAME));
    if (!!usersDataFromLS[userId]) {
      return usersDataFromLS[userId];
    }
  } catch (e) {}
  return null;
}

function saveCityId(userId, cityId) {
  try {
    let usersDataFromLS = JSON.parse(localStorage.getItem(LS_OBJ_NAME)) || {};
    usersDataFromLS[userId] = cityId;
    localStorage.setItem(LS_OBJ_NAME, JSON.stringify(usersDataFromLS));
  } catch (e) {}
}

function removeCityId(userId) {
  try {
    let usersDataFromLS = JSON.parse(localStorage.getItem(LS_OBJ_NAME)) || {};
    usersDataFromLS[userId] = undefined;
    localStorage.setItem(LS_OBJ_NAME, JSON.stringify(usersDataFromLS));
  } catch (e) {}
}

export { getSavedCityId, saveCityId, removeCityId };
