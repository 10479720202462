const actions = {
  START_LOADING_COURIERS: "START_LOADING_COURIERS",
  SUCCESS_LOADING_COURIERS: "SUCCESS_LOADING_COURIERS",
  FAIL_LOADING_COURIERS: "FAIL_LOADING_COURIERS",
  CHANGE_FILTERS_COURIERS: "CHANGE_FILTERS_COURIERS",
  CHANGE_PAGE_COURIERS: "CHANGE_PAGE_COURIERS",
  RESET_STATE_COURIERS: "RESET_STATE_COURIERS",
  CHANGE_PAGE_ASYNC_COURIERS: "CHANGE_PAGE_ASYNC_COURIERS",
  CHANGE_NAME_COURIERS: "CHANGE_NAME_COURIERS",
  CHANGE_PHONE_COURIERS: "CHANGE_PHONE_COURIERS",
  CHANGE_TRANSPORTS_COURIERS: "CHANGE_TRANSPORTS_COURIERS",
  CHANGE_BLOCKED_COURIERS: "CHANGE_BLOCKED_COURIERS",
  CHANGE_SHIFT_COURIERS: "CHANGE_SHIFT_COURIERS",
  INIT_COURIERS: "INIT_COURIERS",
  INIT_COURIERS_BY_PARAMS: "INIT_COURIERS_BY_PARAMS",
  init: (data) => ({
    type: actions.INIT_COURIERS,
    payload: data,
  }),
  changeFilter: (data, actionName) => ({
    type: actions.CHANGE_FILTERS_COURIERS,
    payload: {
      name: actionName,
      data: data,
    },
  }),
  resetState: () => ({
    type: actions.RESET_STATE_COURIERS,
  }),
  changePage: (page) => ({
    type: actions.CHANGE_PAGE_ASYNC_COURIERS,
    payload: page,
  }),
};
export default actions;
