import React from "react";
import { LocaleProvider, Spin } from "antd";
import { injectIntl, IntlProvider } from "react-intl";

import PublicRoutes from "../router";
import { useLanguageLoader } from "../helpers/language/useLanguageLoader";
import LoaderData from "../helpers/api";
import NotificationProvider from "../containers/NotificationContainer";

const App = injectIntl(function ({ intl }) {
  LoaderData.setAcceptLanguage(intl.locale);
  return <PublicRoutes />;
});

function DashApp() {
  const { value } = useLanguageLoader();
  if (!value) return <Spin className="absolute-center" />;

  return (
    <LocaleProvider locale={value.antdLocale}>
      <IntlProvider locale={value.reactIntlLocale} messages={value.messages}>
        <App />
        <NotificationProvider />
      </IntlProvider>
    </LocaleProvider>
  );
}

export default DashApp;
