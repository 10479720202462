const actions = {
  START_LOADING_RESTAURANT: "START_LOADING_RESTAURANT",
  SUCCESS_LOADING_RESTAURANT: "SUCCESS_LOADING_RESTAURANT",
  FAIL_LOADING_RESTAURANT: "FAIL_LOADING_RESTAURANT",
  RESET_STATE_RESTAURANT: "RESET_STATE_RESTAURANT",
  INIT_RESTAURANT: "INIT_RESTAURANT",
  CHANGE_INSTRUCTION: "CHANGE_INSTRUCTION",
  SAVE_DATA: "SAVE_DATA",
  init: (id) => ({
    type: actions.INIT_RESTAURANT,
    payload: id,
  }),
  resetState: () => ({
    type: actions.RESET_STATE_RESTAURANT,
  }),
  changeInstruction: (data) => ({
    type: actions.CHANGE_INSTRUCTION,
    payload: data,
  }),
  saveData: (data) => ({
    type: actions.SAVE_DATA,
    data: data,
  }),
};
export default actions;
