import actions from "./actions";

const initState = {
  data: null,
  date_finish: null,
  date_start: null,
  cityDepended: false,
};

export default function restaurantReducer(state = initState, action) {
  switch (action.type) {
    case actions.SUCCESS_LOADING_STATISTICS:
      return {
        ...state,
        data: action.data.restaurant,
      };

    case actions.RESET_STATE_STATISTICS:
      return {
        ...initState,
      };

    case actions.INIT_STATISTICS_BY_PARAMS:
      return {
        ...state,
        date_finish: action.data.date_finish,
        date_start: action.data.date_start,
        cityDepended: !!action.data.cityDepended ? action.data.cityDepended : false,
      };

    case actions.UPDATE_STATE_BY_TEXT_STATISTICS:
      return {
        ...state,
        [action.data.field]: action.data.value,
      };

    case actions.FAIL_LOADING_STATISTICS:
      return state;

    default:
      return state;
  }
}
