import actions from "./actions";

const initState = {
  data: null,
};

export default function cityReducer(state = initState, action) {
  switch (action.type) {
    case actions.SUCCESS_LOADING_CITY:
      return {
        ...state,
        data: action.data.city,
      };

    case actions.RESET_STATE_CITY:
      return {
        ...initState,
      };

    case actions.FAIL_LOADING_CITY:
      return state;

    default:
      return state;
  }
}
