import fb from "firebase/app";
import "firebase/remote-config";

const firebaseConfig = process.env.__CONFIG__.firebaseConfig;

const getFirebase = () => {
  if (firebaseConfig) return !fb.apps.length ? fb.initializeApp(firebaseConfig) : fb.app();
};

const firebase = getFirebase();
const remoteConfig = process.browser && firebase ? firebase.remoteConfig() : null;
if (remoteConfig) remoteConfig.fetchAndActivate();

const getRemoteConfigByKey = (remoteConfigKey) => {
  const value = remoteConfig?.getString(remoteConfigKey);
  if (value) return JSON.parse(value);
};

export const getWhiteListHeader = () => {
  const config = getRemoteConfigByKey("whitelist_header_hash");
  return config?.[process.env.__CONFIG__.whiteListEnvironment]?.key;
};
