import "antd/dist/antd.css";
import "@bb/components/dist/esm/main.css";
import "@bb/components-kit/dist/esm/main.css";

import styled, { createGlobalStyle } from "styled-components";
import { font, palette } from "styled-theme";

const DashAppHolder = styled.div`
  font-family: ${font("primary", 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette("primary", 0)};
      color: #fff;
    }
  }

  .ant-row:not(.ant-form-item) {
    ${
      "" /* margin-left: -8px;
    margin-right: -8px; */
    };
    &:before,
    &:after {
      display: none;
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export default DashAppHolder;

export const GlobalStyles = createGlobalStyle`
  body {
    .ymaps-2-1-77-copyright__content-cell{
        display: none;
    }
  }
  
  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
