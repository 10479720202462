import actions from "./actions";

const initState = {
  data: null,
  primaryData: null,
  isLoading: false,
  tracks: [],
};

export default function orderReducer(state = initState, action) {
  switch (action.type) {
    case actions.START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.SUCCESS_LOADING_ORDER:
      return {
        ...state,
        data: action.data.order,
        primaryData: action.data.order,
        isLoading: false,
      };
    case actions.SUCCESS_UPDATE_STATUS: {
      const { newState, states } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          state: newState,
          states,
        },
        primaryData: {
          ...state.primaryData,
          state: action.payload,
        },
        isLoading: false,
      };
    }
    case actions.FAIL_UPDATE_STATUS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actions.RESET_STATE_ORDER:
      return {
        ...initState,
      };

    case actions.CHANGE_TRACKS_ORDER:
      return {
        ...state,
        tracks: action.data.tracks,
        isLoading: false,
      };

    case actions.CHANGE_GUESTS_NUMBER:
      return {
        ...state,
        data: {
          ...state.data,
          guest_count: action.payload,
        },
      };

    case actions.RESET_SERVICES_ORDER:
      let servicesInit = state.primaryData.services.slice(0);
      return {
        ...state,
        data: {
          ...state.data,
          guest_count: state.primaryData.guest_count,
          services: servicesInit,
        },
      };

    case actions.UPDATE_STATE_BY_TEXT_ORDER:
      return {
        ...state,
        data: {
          ...state.data,
          to_address: {
            ...state.data.to_address,
            [action.payload.field]: action.payload.value,
          },
        },
      };

    case actions.UPDATE_FROM_ADDRESS_ORDER:
      return {
        ...state,
        data: {
          ...state.data,
          from_address: {
            ...state.data.from_address,
            [action.payload.field]: action.payload.value,
          },
        },
      };

    case actions.UPDATE_DATA_ORDER:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: action.payload.value,
        },
      };

    case actions.UPDATE_STATE_COUNT_COMPOSITION_ORDER:
      let services = state.data.services.slice(0).map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            count: action.payload.count,
          };
        }
        return item;
      });
      return {
        ...state,
        data: {
          ...state.data,
          services,
        },
      };

    case actions.FAIL_LOADING_ORDER:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
