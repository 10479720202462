import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

function generateParamsFromFilters(partnersState, currentCity) {
  let filters = partnersState.filters;
  let params = {};

  if (partnersState.currentPage > 1) {
    params.offset = partnersState.perPage * (partnersState.currentPage - 1);
  }

  if (partnersState.network) {
    params.network = partnersState.network;
  }

  if (!!filters.hidden) {
    params.hidden = filters.hidden;
  }

  if (!!filters.name) {
    params.q = filters.name;
  }

  if (!!currentCity && !!currentCity.id) {
    params.city = currentCity.id;
  }

  params.count = 20;
  return params;
}

export function* loadData() {
  yield takeEvery("START_LOADING_PARTNERS", function* () {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    let params = generateParamsFromFilters(state.Partners, state.Auth.city);
    try {
      let partners = yield LoaderData.get(apiUrls.placesList, params);
      if (partners.success) {
        let transfData = partners.data.map((item) => {
          return { ...item, key: item.id };
        });
        yield put({
          type: actions.SUCCESS_LOADING_PARTNERS,
          data: {
            partners: transfData,
            total: partners.pagination.total,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_PARTNERS,
          payload: partners,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_PARTNERS,
        payload: e,
      });
    }
  });
}

export function* changePage() {
  yield takeEvery(actions.CHANGE_PAGE_ASYNC_PARTNERS, function* ({ payload }) {
    yield put({
      type: actions.CHANGE_PAGE_PARTNERS,
      data: payload,
    });

    yield put({
      type: actions.START_LOADING_PARTNERS,
    });
  });
}

export function* changeFilter() {
  yield takeEvery(actions.CHANGE_FILTERS_PARTNERS, function* ({ payload }) {
    yield put({
      type: payload.name,
      data: payload.data,
    });

    yield put({
      type: actions.CHANGE_PAGE_PARTNERS,
      data: 1,
    });

    yield put({
      type: actions.START_LOADING_PARTNERS,
    });
  });
}

export function* init() {
  yield takeEvery(actions.INIT_PARTNERS, function* ({ payload }) {
    yield put({
      type: actions.INIT_PARTNERS_DATA,
      payload: payload,
    });
    yield put({
      type: actions.START_LOADING_PARTNERS,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_PARTNERS, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export default function* rootSaga() {
  yield all([fork(loadData), fork(init), fork(changePage), fork(failLoading), fork(changeFilter)]);
}
