import React from "react";
import { useAsyncFn } from "react-use";

import { loadLocale } from "../../settings/languageProvider/load-locale";
import { useLanguage, useLanguageSetter } from "./useLanguage";

const loadLocalesOrLog = (language) =>
  loadLocale(language).catch((e) => {
    console.error(e);
    throw e;
  });

export function useLanguageLoader() {
  const language = useLanguage();
  const setLanguage = useLanguageSetter();
  const [state, loadLocaleAsync] = useAsyncFn(loadLocalesOrLog);

  React.useEffect(() => {
    setLanguage(language);
  }, [language, setLanguage]);

  React.useEffect(() => {
    loadLocaleAsync(language);
  }, [loadLocaleAsync, language]);

  return state;
}
