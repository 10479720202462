import { copyrightModifier } from "./modifiers/copyright";
import { matchISO6391WithPosixLocale } from "../../constants/available-languages";

function prepareMessages(messages) {
  const preparedMessages = Object.entries(messages).map(([key, message]) => [key, copyrightModifier(message)]);
  return Object.fromEntries(preparedMessages);
}

export async function loadLocale(language) {
  const posixLocale = matchISO6391WithPosixLocale[language];
  const [antdLocale, messagesFetch] = await Promise.all([
    import("antd/lib/locale-provider/" + posixLocale).then(pickDefault),
    fetch(location.protocol + "//" + location.host + `/translations/${language}.json`),
  ]);

  const messages = await messagesFetch.json();

  return {
    messages: prepareMessages(messages),
    antdLocale,
    reactIntlLocale: language,
  };
}

function pickDefault(object) {
  return object.default;
}
