const actions = {
  START_SEND_PROMO: "START_SEND_PROMO",
  SUCCESS_SEND_PROMO: "SUCCESS_SEND_PROMO",
  FAIL_SEND_PROMO: "FAIL_SEND_PROMO",
  sendPromocode: (data) => ({
    type: actions.START_SEND_PROMO,
    payload: data,
  }),
};

export default actions;
