import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

function generateParamsFromFilters(clientsState, currentCity) {
  let filters = clientsState.filters;
  let params = {};

  if (clientsState.currentPage > 1) {
    params.offset = clientsState.perPage * (clientsState.currentPage - 1);
  }

  if (!!filters.typeUser) {
    params.type = filters.typeUser;
  }

  if (!!filters.search) {
    params.q = filters.search;
  }

  if (filters.cityDepended && !!currentCity && !!currentCity.id) {
    params.city = currentCity.id;
  }

  params.count = 20;

  return params;
}

export function* loadData() {
  yield takeEvery(actions.START_LOADING_CLIENTS, function* () {
    yield call(checkAccessToken);
    const state = yield select();
    if (!state.Auth.idToken) {
      yield cancel();
    }

    let params = generateParamsFromFilters(state.Clients, state.Auth.city);
    try {
      let clients = yield LoaderData.get(apiUrls.clientsList, params);
      if (clients.success) {
        let transfData = clients.data.map((item) => {
          return { ...item, key: item.id };
        });
        yield put({
          type: actions.SUCCESS_LOADING_CLIENTS,
          data: {
            clients: transfData,
            total: clients.pagination.total,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_CLIENTS,
          payload: clients,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_CLIENTS,
        payload: e,
      });
    }
  });
}

export function* changeFilter() {
  yield takeEvery(actions.CHANGE_FILTERS_CLIENTS, function* ({ payload }) {
    yield put({
      type: payload.name,
      data: payload.data,
    });

    yield put({
      type: actions.CHANGE_PAGE_CLIENTS,
      data: 1,
    });

    yield put({
      type: actions.START_LOADING_CLIENTS,
    });
  });
}

export function* changePage() {
  yield takeEvery(actions.CHANGE_PAGE_ASYNC_CLIENTS, function* ({ payload }) {
    yield put({
      type: actions.CHANGE_PAGE_CLIENTS,
      data: payload,
    });

    yield put({
      type: actions.START_LOADING_CLIENTS,
    });
  });
}

export function* init() {
  yield takeEvery(actions.INIT_CLIENTS, function* ({ payload }) {
    yield put({
      type: actions.INIT_CLIENTS_DATA,
      payload,
    });
    yield put({
      type: actions.START_LOADING_CLIENTS,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_CLIENTS, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export default function* rootSaga() {
  yield all([fork(loadData), fork(changeFilter), fork(init), fork(changePage), fork(failLoading)]);
}
