import actions from "./actions";

const initState = {
  data: null,
  primaryData: null,
};

export default function clientReducer(state = initState, action) {
  switch (action.type) {
    case actions.SUCCESS_LOADING_CLIENT:
      return {
        ...initState,
        data: action.data.client,
        primaryData: action.data.client,
      };

    case actions.RESET_STATE_CLIENT:
      return {
        ...initState,
      };

    case actions.FAIL_LOADING_CLIENT:
      return state;

    case actions.CHANGE_COMMENT_CLIENT:
      return {
        ...state,
        data: {
          ...state.data,
          comment: action.data,
        },
      };

    default:
      return state;
  }
}
