import React from "react";
import { withRouter } from "react-router-dom";

class PageView extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!window.gtag) {
      let prevLocation = prevProps.location;
      let currLocation = this.props.location;
      if (prevLocation.pathname !== currLocation.pathname) {
        window.gtag("event", "pageview", document.location.origin + currLocation.pathname);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(PageView);
