import actions from "./actions";

const initialState = {
  googleUserData: null,
  googleSheetsData: null,
  currentFrame: "Login",
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        googleUserData: action.payload,
        currentFrame: "Loading",
      };
    case actions.LOGOUT:
      return initialState;
    case actions.SHEETS_SUCCESS:
      return {
        ...state,
        googleSheetsData: action.payload,
        currentFrame: "Map",
      };
    case actions.SHEETS_ERROR: {
      sessionStorage.removeItem("GoogleData");
      return initialState;
    }

    default:
      return state;
  }
}
