const actions = {
  START_LOADING_CLIENTS: "START_LOADING_CLIENTS",
  SUCCESS_LOADING_CLIENTS: "SUCCESS_LOADING_CLIENTS",
  FAIL_LOADING_CLIENTS: "FAIL_LOADING_CLIENTS",
  CHANGE_FILTERS_CLIENTS: "CHANGE_FILTERS_CLIENTS",
  CHANGE_PAGE_CLIENTS: "CHANGE_PAGE_CLIENTS",
  RESET_STATE_CLIENTS: "RESET_STATE_CLIENTS",
  CHANGE_PAGE_ASYNC_CLIENTS: "CHANGE_PAGE_ASYNC_CLIENTS",
  CHANGE_SEARCH_CLIENTS: "CHANGE_SEARCH_CLIENTS",
  INIT_CLIENTS: "INIT_CLIENTS",
  INIT_CLIENTS_DATA: "INIT_CLIENTS_DATA",
  init: (initData) => ({
    type: actions.INIT_CLIENTS,
    payload: {
      page: 1,
      search: "",
      ...initData,
    },
  }),
  changeFilter: (data, actionName) => ({
    type: actions.CHANGE_FILTERS_CLIENTS,
    payload: {
      name: actionName,
      data: data,
    },
  }),
  resetState: () => ({
    type: actions.RESET_STATE_CLIENTS,
  }),
  changePage: (page) => ({
    type: actions.CHANGE_PAGE_ASYNC_CLIENTS,
    payload: page,
  }),
};
export default actions;
