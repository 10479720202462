const actions = {
  START_LOADING_CLIENT: "START_LOADING_CLIENT",
  SUCCESS_LOADING_CLIENT: "SUCCESS_LOADING_CLIENT",
  FAIL_LOADING_CLIENT: "FAIL_LOADING_CLIENT",
  RESET_STATE_CLIENT: "RESET_STATE_CLIENT",
  INIT_CLIENT: "INIT_CLIENT",
  CHANGE_COMMENT_CLIENT: "CHANGE_COMMENT_CLIENT",
  SAVE_DATA_CLIENT: "SAVE_DATA_CLIENT",
  init: (id) => ({
    type: actions.INIT_CLIENT,
    payload: id,
  }),
  resetState: () => ({
    type: actions.RESET_STATE_CLIENT,
  }),
  changeComment: (value) => ({
    type: actions.CHANGE_COMMENT_CLIENT,
    data: value,
  }),
  save: () => ({
    type: actions.SAVE_DATA_CLIENT,
  }),
};
export default actions;
