import actions from "./actions";

const initState = {
  data: null,
  availableStates: [],
  loading: false,
  total: 0,
  currentPage: 1,
  perPage: 50,
  places: [],
  idClient: null,
  idCourier: null,
  filters: {
    dateStart: "",
    dateEnd: "",
    states: [],
    places: [],
    number: "",
  },
};

export default function ordersReducer(state = initState, action) {
  switch (action.type) {
    case actions.SUCCESS_LOADING_ORDERS:
      return {
        ...state,
        data: action.data.orders,
        total: action.data.total,
      };
    case actions.SET_LOAD_PLACES_ORDERS:
      return {
        ...state,
        places: action.data,
      };

    case actions.CHANGE_PAGE_ORDERS:
      return {
        ...state,
        currentPage: action.data,
      };

    case actions.SET_PLACES_IN_FILTERS_ORDERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          places: action.data,
        },
      };

    case actions.CHANGE_FILTER_TS_FROM_ORDERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateStart: action.data,
        },
      };

    case actions.CHANGE_FILTER_NUMBER_ORDERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          number: action.data,
        },
      };

    case actions.CHANGE_FILTER_TS_TO_ORDERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateEnd: action.data,
        },
      };

    case actions.CHANGE_FILTER_STATES_ORDERS:
      let array = state.availableStates.filter((item) => !!action.data.find((state) => state === item.name));
      return {
        ...state,
        filters: {
          ...state.filters,
          states: array,
        },
      };

    case actions.INIT_TABLE_BY_PARAMS:
      let places = !!action.data.idRestaurant ? [action.data.idRestaurant] : [];
      places = !!action.data && !!action.data.places ? action.data.places : places;
      return {
        ...state,
        availableStates: action.data.states,
        currentPage: !!action.data && !!action.data.page ? action.data.page : 1,
        filters: {
          ...state.filters,
          idClient: action.data.idClient,
          idCourier: action.data.idCourier,
          places: places,
          states: !!action.data.activeStates ? action.data.activeStates : [],
          dateStart: !!action.data && !!action.data.ts_from ? action.data.ts_from : "",
          dateEnd: !!action.data && !!action.data.ts_to ? action.data.ts_to : "",
          number: !!action.data && !!action.data.number ? action.data.number : "",
        },
      };

    case actions.RESET_STATE_ORDERS:
      return {
        ...initState,
      };

    case actions.FAIL_LOADING_ORDERS:
      return state;

    default:
      return state;
  }
}
