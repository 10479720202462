const options = [
  {
    id: "orders",
    path: "orders",
    label: "sidebar.orders",
    leftIcon: "ion-android-menu",
    children: [
      {
        path: "orders/active",
        label: "sidebar.orders.active",
      },
      {
        path: "orders/history",
        label: "sidebar.orders.history",
      },
    ],
  },
  {
    id: "clients",
    path: "clients",
    label: "sidebar.clients",
    leftIcon: "ion-android-contact",
  },
  {
    id: "couriersList",
    path: "couriersList",
    label: "sidebar.couriers",
    leftIcon: "ion-android-bicycle",
    children: [
      {
        path: "couriers",
        label: "sidebar.couriers.list",
      },
      {
        path: "couriers/waitlist",
        label: "couriers.waiting",
      },
      {
        path: "couriers/list",
        label: "common.all",
      },
      {
        path: "couriers/map",
        label: "couriers.map",
      },
    ],
  },
  {
    id: "partnersList",
    path: "partnersList",
    label: "sidebar.partners",
    leftIcon: "ion-android-happy",
    children: [
      {
        path: "partners/all",
        label: "sidebar.partners.all",
      },
      {
        path: "partners/hidden",
        label: "sidebar.partners.hidden",
      },
    ],
  },
  {
    id: "omnidesk",
    label: "sidebar.omnidesk.tickets",
    leftIcon: "ion-ios-filing",
    link: "https://broniboy.omnidesk.ru/staff/",
    rightIcon: "ion-ios-redo",
  },
  {
    id: "knowledge",
    label: "sidebar.omnidesk.base",
    leftIcon: "ion-help",
    link: "https://broniboy.omnidesk.ru/knowledge_base/",
    rightIcon: "ion-ios-redo",
  },
  {
    id: "citiesMap",
    path: "citiesMap",
    label: "sidebar.cities_map",
    leftIcon: "ion-android-globe",
  },
].filter((menuItem) => process.env.__CONFIG__.menuLinks.includes(menuItem.id));

export default options;
