import { all, takeEvery, put, fork, select, call, cancel } from "redux-saga/effects";
import LoaderData from "../../helpers/api";
import actions from "./actions";
import { apiUrls } from "../../settings";
import pathToReg from "path-to-regexp";
import { checkAccessToken } from "../auth/saga";
import { globalEventBus, SUCCESS_NOTIFICATION, ERROR_NOTIFICATION } from "../../helpers/globalEventBus";

export function* loadData() {
  yield takeEvery(actions.START_LOADING_RESTAURANT, function* (action) {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      let url = pathToReg.compile(apiUrls.restaurantOne);
      let restaurant = yield LoaderData.get(
        url({
          id: action.data,
        }),
      );
      if (restaurant.success) {
        yield put({
          type: actions.SUCCESS_LOADING_RESTAURANT,
          data: {
            restaurant: restaurant.data,
          },
        });
      } else {
        yield put({
          type: actions.FAIL_LOADING_RESTAURANT,
          payload: restaurant,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_RESTAURANT,
        payload: e,
      });
    }
  });
}

export function* init() {
  yield takeEvery(actions.INIT_RESTAURANT, function* ({ payload }) {
    yield put({
      type: actions.START_LOADING_RESTAURANT,
      data: payload,
    });
  });
}

export function* failLoading() {
  yield takeEvery(actions.FAIL_LOADING_RESTAURANT, function* ({ payload }) {
    yield globalEventBus.emit(ERROR_NOTIFICATION, { id: "common.error", desc: payload.message });
  });
}

export function* saveData() {
  yield takeEvery(actions.SAVE_DATA, function* (action) {
    try {
      yield call(checkAccessToken);
      const state = yield select();
      if (!state.Auth.idToken) {
        yield cancel();
      }

      let restaurant = yield LoaderData.post(apiUrls.restaurantUpdate, {
        place_id: state.Restaurant.data.id,
        ...action.data,
      });
      if (restaurant.success) {
        yield globalEventBus.emit(SUCCESS_NOTIFICATION, { id: "common.success" });
        yield put({
          type: actions.SUCCESS_LOADING_RESTAURANT,
          data: {
            restaurant: restaurant.data,
          },
        });
      } else {
        if (restaurant.status === 409) {
          const state = yield select();
          yield globalEventBus.emit(ERROR_NOTIFICATION, {
            id: "common.server_error",
            desc: "restaurant.card.edit_error.description",
          });
          yield put({
            type: actions.INIT_RESTAURANT,
            payload: state.Restaurant.primaryData.id,
          });
          return;
        }
        yield put({
          type: actions.FAIL_LOADING_RESTAURANT,
          payload: restaurant,
        });
      }
    } catch (e) {
      yield put({
        type: actions.FAIL_LOADING_RESTAURANT,
        payload: e,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(loadData), fork(init), fork(failLoading), fork(saveData)]);
}
