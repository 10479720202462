import React from "react";
import { notification } from "antd";
import { injectIntl, useIntl } from "react-intl";
import { useEventEmitter } from "@worksolutions/react-utils";

import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION, globalEventBus } from "../../helpers/globalEventBus";

function NotificationProvider() {
  const { formatMessage } = useIntl();

  useEventEmitter(globalEventBus, ERROR_NOTIFICATION, ({ id, desc }) => showNotification("error", id, desc));

  useEventEmitter(globalEventBus, SUCCESS_NOTIFICATION, ({ id, desc }) => showNotification("success", id, desc));

  const showNotification = React.useCallback(
    (type = "error", id = "common.error", description) => {
      notification[type]({
        message: formatMessage({ id }),
        description: description && formatMessage({ id: description }),
      });
    },
    [formatMessage],
  );

  return <></>;
}

export default React.memo(injectIntl(NotificationProvider));
