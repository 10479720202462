const actions = {
  START_LOADING_CITY: "START_LOADING_CITY",
  SUCCESS_LOADING_CITY: "SUCCESS_LOADING_CITY",
  FAIL_LOADING_CITY: "FAIL_LOADING_CITY",
  RESET_STATE_CITY: "RESET_STATE_CITY",
  INIT_CITY: "INIT_CITY",
  init: (id) => ({
    type: actions.INIT_CITY,
    payload: id,
  }),
  resetState: () => ({
    type: actions.RESET_STATE_CITY,
  }),
};
export default actions;
