const actions = {
  START_LOADING_STATISTICS: "START_LOADING_STATISTICS",
  SUCCESS_LOADING_STATISTICS: "SUCCESS_LOADING_STATISTICS",
  FAIL_LOADING_STATISTICS: "FAIL_LOADING_STATISTICS",
  RESET_STATE_STATISTICS: "RESET_STATE_STATISTICS",
  INIT_STATISTICS_BY_PARAMS: "INIT_STATISTICS_BY_PARAMS",
  INIT_STATISTICS: "INIT_STATISTICS",
  UPDATE_STATE_BY_TEXT_STATISTICS: "UPDATE_STATE_BY_TEXT_STATISTICS",
  CHANGE_FILTERS_STATISTICS: "CHANGE_FILTERS_STATISTICS",
  init: (data) => ({
    type: actions.INIT_STATISTICS,
    payload: data,
  }),
  resetState: () => ({
    type: actions.RESET_STATE_STATISTICS,
  }),
  updateStateByText: (field, value) => ({
    type: actions.CHANGE_FILTERS_STATISTICS,
    payload: {
      field,
      value,
    },
  }),
};
export default actions;
