const actions = {
  START_LOADING: "START_LOADING",
  START_LOADING_ORDER: "START_LOADING_ORDER",
  UPDATE_RECEIPTS: "UPDATE_RECEIPTS",
  SUCCESS_UPDATE_RECEIPTS: "SUCCESS_UPDATE_RECEIPTS",
  FAIL_UPDATE_RECEIPTS: "FAIL_UPDATE_RECEIPTS",
  UPDATE_STATUS: "UPDATE_STATUS",
  SUCCESS_UPDATE_STATUS: "SUCCESS_UPDATE_STATUS",
  FAIL_UPDATE_STATUS: "FAIL_UPDATE_STATUS",
  SUCCESS_LOADING_ORDER: "SUCCESS_LOADING_ORDER",
  FAIL_LOADING_ORDER: "FAIL_LOADING_ORDER",
  RESET_STATE_ORDER: "RESET_STATE_ORDER",
  UPDATE_STATE_BY_TEXT_ORDER: "UPDATE_STATE_BY_TEXT_ORDER",
  UPDATE_STATE_COUNT_COMPOSITION_ORDER: "UPDATE_STATE_COUNT_COMPOSITION_ORDER",
  INIT_ORDER: "INIT_ORDER",
  SAVE_ORDER: "SAVE_ORDER",
  SAVE_COUNT_COMPOSITION_ORDER: "SAVE_COUNT_COMPOSITION_ORDER",
  RESET_SERVICES_ORDER: "RESET_SERVICES_ORDER",
  LOAD_TRACKS_ORDER: "LOAD_TRACKS_ORDER",
  CHANGE_TRACKS_ORDER: "CHANGE_TRACKS_ORDER",
  CHANGE_GUESTS_NUMBER: "CHANGE_GUESTS_NUMBER",
  UPDATE_DATA_ORDER: "UPDATE_DATA_ORDER",
  UPDATE_FROM_ADDRESS_ORDER: "UPDATE_FROM_ADDRESS_ORDER",
  UPDATE_REQUIRED_COURIER_TYPE: "UPDATE_REQUIRED_COURIER_TYPE",
  init: (id) => ({
    type: actions.INIT_ORDER,
    payload: id,
  }),
  startLoading: () => ({
    type: actions.START_LOADING,
  }),
  updateReceipts: (receipts, callback = null) => ({
    type: actions.UPDATE_RECEIPTS,
    payload: receipts,
    callback,
  }),
  updateRequiredCourierType: (courierType, callback = null) => ({
    type: actions.UPDATE_REQUIRED_COURIER_TYPE,
    payload: courierType,
    callback,
  }),
  updateStatus: (status) => ({
    type: actions.UPDATE_STATUS,
    payload: status,
  }),
  successUpdateStatus: (status) => ({
    type: actions.SUCCESS_UPDATE_STATUS,
    payload: status,
  }),
  failUpdateStatus: () => ({
    type: actions.FAIL_UPDATE_STATUS,
  }),
  resetState: () => ({
    type: actions.RESET_STATE_ORDER,
  }),
  updateStateByText: (field, value) => ({
    type: actions.UPDATE_STATE_BY_TEXT_ORDER,
    payload: {
      field,
      value,
    },
  }),
  updateDataOrder: (field, value) => ({
    type: actions.UPDATE_DATA_ORDER,
    payload: {
      field,
      value,
    },
  }),
  saveData: (data) => ({
    type: actions.SAVE_ORDER,
    payload: data,
  }),
  changeGuestNumber: (data) => ({
    type: actions.CHANGE_GUESTS_NUMBER,
    payload: data,
  }),
  updateCountComposition: (data) => ({
    type: actions.UPDATE_STATE_COUNT_COMPOSITION_ORDER,
    payload: data,
  }),
  saveCountComposition: (data) => ({
    type: actions.SAVE_COUNT_COMPOSITION_ORDER,
    payload: data,
  }),
  resetServices: () => ({
    type: actions.RESET_SERVICES_ORDER,
  }),
  refreshTracks: (id) => ({
    type: actions.LOAD_TRACKS_ORDER,
    data: id,
  }),
  updateFromAddress: (field, value) => ({
    type: actions.UPDATE_FROM_ADDRESS_ORDER,
    payload: {
      field,
      value,
    },
  }),
};
export default actions;
